import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { BsCalendarX } from 'react-icons/bs';
import { ButtonBase, PageTitleBar } from '../common';
import { ABILITY_TYPES, BADGE_STYLE, TITLES, URLS } from '../common/constants';
import { useApiService } from '../context'; //theme
import AddAbsencesFormContainer from './AddAbsencesFormContainer';
import StarterImg from '../common/img/explore.png';
import Card from './Card';
import Chart from 'components/common/Charts';
import { AbilityContext } from 'components/context/ability-context';
import { useAbility } from '@casl/react';
import { convertObjectToArray } from 'components/common/utils';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const areDatesInMonth = (sDate, eDate, mDate) => {
  const startOfMonth = moment(mDate).startOf('month');
  const endOfMonth = moment(mDate).endOf('month');
  var startDate = moment(sDate);
  var endDate = moment(eDate);

  return (
    startDate.isBetween(startOfMonth, endOfMonth, 'days', '[]') ||
    endDate.isBetween(startOfMonth, endOfMonth, 'days', '[]')
  );
};

const getDays = (selectedMonth, filterLeaveRequestsByMonth, leaveType) =>
  _.times(moment(selectedMonth).daysInMonth(), (n) => {
    let count = 0;
    const setDate = moment(selectedMonth).set('date', n + 1);

    for (let x = 0; x < filterLeaveRequestsByMonth.length; x++) {
      if (
        setDate.isBetween(
          moment(filterLeaveRequestsByMonth[x].startDate),
          moment(filterLeaveRequestsByMonth[x].endDate),
          'days',
          '[]'
        ) &&
        filterLeaveRequestsByMonth[x].leavePolicy === leaveType
      ) {
        count = count + 1;
      }
    }

    return count;
  });

const Absences = () => {
  const { rotaPadUser, auth, rotaPadAbsencesTopic } = useApiService();
  const [visibleLeft, setVisibleLeft] = useState(false);
  const ability = useAbility(AbilityContext);

  const [selected, setSelected] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().format());
  const [selectedLabel, setSelectedLabel] = useState(6);
  const [selectedLegends, setSelectedLegends] = useState({
    Vacation: true,
    'Personal Time Off': true,
    'Unpaid Vacation': true,
    'Time in lieu': true,
    'Working Remotely': true,
    Conference: true,
    'Stats Holiday': true,
  });

  const [newGroup, setNewGroup] = useState({
    autoApproved: true,
    endDate: '',
    leavePolicy: '',
    reason: '',
    startDate: '',
    startEndDateTimeRange: '',
    // approver: '',
    // user: '',
    status: 'APPROVED',
    uid: undefined,
    createdAt: moment().format(),
  });

  // const leaveRequests = [
  //   {
  //     _id: '645a09db893e2b00143db4d0',
  //     leavePolicy: 'Vacation',
  //     startDate: '2023-12-23T23:00:00.000+00:00',
  //     endDate: '2023-12-26T23:00:00.000+00:00',
  //     reason: 'Vacation',
  //     autoApproved: true,
  //     status: 'APPROVED',
  //     user: {
  //       name: 'Esther Akanbi',
  //       email: 'ibukunakanbi0@gmail.com',
  //     },
  //     approver: {
  //       name: 'ROTAPAD SUPPORT',
  //       email: 'rotapadv3@gmail.com',
  //     },
  //     createdAt: '2023-12-09T08:52:39.000+00:00',
  //   },
  //   {
  //     _id: '645a09db893e2b00143db412',
  //     leavePolicy: 'Personal Time Off',
  //     startDate: '2023-11-23T23:00:00.000+00:00',
  //     endDate: '2023-11-26T23:00:00.000+00:00',
  //     reason: 'Personal Time Off',
  //     autoApproved: true,
  //     status: 'APPROVED',
  //     user: {
  //       name: 'Esther Akanbi',
  //       email: 'ibukunakanbi0@gmail.com',
  //     },
  //     approver: {
  //       name: 'ROTAPAD SUPPORT',
  //       email: 'rotapadv3@gmail.com',
  //     },
  //     createdAt: '2023-12-09T08:52:39.000+00:00',
  //   },
  // ];

  const optionsValue = useMemo(() => {
    const leaveRequests = convertObjectToArray({ item: rotaPadAbsencesTopic });
    const { color, dataIndex } = selected || {};

    const dates = _.times(moment(selectedMonth).daysInMonth(), (n) =>
      String(n + 1)
    );

    const filterLeaveRequestsByMonth = (leaveRequests || []).filter((obj) =>
      areDatesInMonth(obj.startDate, obj.endDate, selectedMonth)
    );

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      legend: {
        show: true,
        left: 0,
        bottom: 0,
        icon: 'circle',
        selected: selectedLegends,
      },
      grid: {
        top: 20,
        left: 0,
        right: 0,
        bottom: 60,
        containLabel: true,
      },
      yAxis: {
        type: 'value',
      },
      xAxis: {
        type: 'category',
        data: dates,
        axisLabel: {
          interval: 0,
          color: function (value, index) {
            if (index === dataIndex) {
              return color;
            } else {
              return '#555';
            }
          },

          backgroundColor: '#efecff',
          fontWeight: 'bold',
          padding: 4,
          formatter: (value, index) => {
            if (index === dataIndex) {
              return `{${index}|${value}}`;
            } else {
              return `{${index}|${value}}`;
            }
          },
          rich:
            selectedLabel || selectedLabel === 0
              ? {
                  [selectedLabel]: {
                    color: '#6554c0',
                    fontWeight: 'bold',
                  },
                }
              : {},
        },
        triggerEvent: true,
      },
      series: [
        {
          name: 'Vacation',
          type: 'bar',
          stack: 'total',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'series',
          },
          data: getDays(selectedMonth, filterLeaveRequestsByMonth, 'Vacation'),
          itemStyle: { color: '#23b7e5' },
        },
        {
          name: 'Personal Time Off',
          type: 'bar',
          stack: 'total',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'series',
          },
          data: getDays(
            selectedMonth,
            filterLeaveRequestsByMonth,
            'Personal Time Off'
          ),
          itemStyle: { color: '#65bc34' },
        },
        {
          name: 'Unpaid Vacation',
          type: 'bar',
          stack: 'total',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'series',
          },
          data: getDays(
            selectedMonth,
            filterLeaveRequestsByMonth,
            'Unpaid Vacation'
          ),
          itemStyle: { color: '#047a9e' },
        },
        {
          name: 'Time in lieu',
          type: 'bar',
          stack: 'total',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'series',
          },
          data: getDays(
            selectedMonth,
            filterLeaveRequestsByMonth,
            'Time in lieu'
          ),
          itemStyle: { color: '#ff006e' },
        },
        {
          name: 'Working Remotely',
          type: 'bar',
          stack: 'total',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'series',
          },
          data: getDays(
            selectedMonth,
            filterLeaveRequestsByMonth,
            'Working Remotely'
          ),
          itemStyle: { color: '#ff6300' },
        },
        {
          name: 'Conference',
          type: 'bar',
          stack: 'total',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'series',
          },
          data: getDays(
            selectedMonth,
            filterLeaveRequestsByMonth,
            'Conference'
          ),
          itemStyle: { color: '#8846b5' },
        },
        {
          name: 'Stats Holiday',
          type: 'bar',
          stack: 'total',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'series',
          },
          data: getDays(
            selectedMonth,
            filterLeaveRequestsByMonth,
            'Stats Holiday'
          ),
          itemStyle: { color: '#00000094' },
        },
      ],
    };
  }, [
    selected,
    selectedLabel,
    selectedMonth,
    rotaPadAbsencesTopic,
    selectedLegends,
  ]);

  const canRotaPadUserUpdate = useMemo(
    () =>
      ability &&
      ability.can(ABILITY_TYPES.read, TITLES[`${URLS.absences}`]) &&
      ability.can(ABILITY_TYPES.update, TITLES[`${URLS.absences}`]),
    [ability, rotaPadUser]
  );
  const getFilteredLeaves = useMemo(() => {
    const leaveRequests = convertObjectToArray({ item: rotaPadAbsencesTopic });
    const filteredData = leaveRequests.filter((item) => {
      // Get the type of the item
      const itemType = item.leavePolicy;

      // Check if the type exists in filterObject and its value is true
      return selectedLegends[itemType] === true;
    });

    // console.log('selectedLegends ==== ', selectedLegends);
    return filteredData;
  }, [rotaPadAbsencesTopic, selectedLegends]);
  if (!rotaPadUser) return null;

  // console.log('selected ==== ', selected);
  // console.log('getFilteredLeaves ==== ', getFilteredLeaves);
  return (
    <div className='container-fluid mx-0 px-0'>
      <div className='row'>
        <div className='col-12 mt-0'>
          <AddAbsencesFormContainer
            visibleLeft={visibleLeft}
            setVisibleLeft={setVisibleLeft}
            auth={auth}
            newGroup={newGroup}
            setNewGroup={setNewGroup}
          />

          <PageTitleBar
            title='Absences'
            count={
              _.isObject(rotaPadAbsencesTopic) ? getFilteredLeaves.length : '0'
            }
            badgeStyle={BADGE_STYLE.Menu}
            handleClickAdd={() => setVisibleLeft(true)}
            canRotaPadUserUpdate={true}
          />
          <div className='card shadow-sm mb-3 px-4 pt-4'>
            <div className='d-flex justify-content-between mb-2 pb-3 border-bottom'>
              <div>
                <button
                  onClick={() =>
                    setSelectedMonth(
                      moment(selectedMonth)
                        .subtract(1, 'months')
                        .format()
                        .toString()
                    )
                  }
                  className='btn btn-outline-primary btnPrimaryComments btn-sm me-1 ps-1'
                >
                  {/* <i
													className='pi pi-angle-left'
													style={{ fontSize: "12px", marginRight: "4px" }}></i> */}
                  <MdKeyboardArrowLeft
                    style={{ fontSize: '12px', marginTop: '-3px' }}
                  />
                  Previous Month
                </button>
              </div>
              <div className='fw-semibold'>
                {moment(selectedMonth).format('MMMM YYYY')}
              </div>
              <div>
                <button
                  onClick={() =>
                    setSelectedMonth(
                      moment(selectedMonth).add(1, 'months').format().toString()
                    )
                  }
                  className='btn btn-outline-primary btnPrimaryComments btn-sm me-1 pe-1'
                >
                  Next Month
                  <MdKeyboardArrowRight
                    style={{ fontSize: '12px', marginTop: '-3px' }}
                  />
                </button>
              </div>
            </div>
            <div
              className='pb-1 mb-3'
              style={{ height: '300px' }}
            >
              <Chart
                option={optionsValue}
                handleOnClick={(x) => setSelected(x)}
                handleLabelOnClick={(x) => setSelectedLabel(x)}
                handleLegendOnClick={(x) => {
                  // console.log('x', x);
                  setSelectedLegends(x);
                }}
              />
            </div>
          </div>

          {_.isObject(rotaPadAbsencesTopic) &&
          Object.keys(rotaPadAbsencesTopic).length > 0 ? (
            <div className='container-fluid py-2 px-0'>
              <div className='row g-3 '>
                <>
                  {_.sortBy(getFilteredLeaves, ['startDate']).map((key) => {
                    return (
                      <Card
                        key={key.uid}
                        setNewGroup={setNewGroup}
                        setVisibleLeft={setVisibleLeft}
                        canRotaPadUserUpdate={canRotaPadUserUpdate}
                        {...key}
                      />
                    );
                  })}
                </>
                {/* <hr />
                {Object.keys(rotaPadAbsencesTopic).map((key) => {
                  return (
                    <Card
                      key={key}
                      setNewGroup={setNewGroup}
                      setVisibleLeft={setVisibleLeft}
                      canRotaPadUserUpdate={canRotaPadUserUpdate}
                      {...rotaPadAbsencesTopic[key]}
                    />
                  );
                })} */}
              </div>
            </div>
          ) : (
            <>
              <div className='card mt-3 text-center'>
                <div className='pt-4 pb-2 text-muted'>
                  <img src={StarterImg} />
                </div>
                <div className='d-flex justify-content-center px-4 mb-2'>
                  <div className='text-muted w-50'>
                    <div className=''>
                      Create an absence request to let your manager and team
                      know when you are out-of-office. Options used and allowed
                      for team members to use vary based on your organization’s
                      work policies.
                    </div>
                    <div className='pt-4 mb-4'>
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='default'
                        className='w-50'
                        onClick={() => setVisibleLeft(true)}
                      >
                        <i
                          className='pi pi-user-plus'
                          style={{
                            fontSize: '12px',
                            marginRight: '4px',
                          }}
                        ></i>
                        Request leave
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Absences;
