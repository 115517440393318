import styled from '@emotion/styled';
import { useCallback } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOpenInNew } from 'react-icons/md';
import { RiEditLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Card as PageCard, Pill, RoundButtonBase, ToolTip } from '../../common';
import { useApiService } from '../../context';
import { BADGE_STYLE } from '../../common/constants';
import { SimpleDialogContainer, simpleConfirm } from 'react-simple-dialogs';
import { BiUser } from 'react-icons/bi';
import { TbMessage2Plus } from 'react-icons/tb';

const RoundedPill = styled.div`
  padding: 7px;
  color: rgb(255, 255, 255);
  background-color: rgba(89, 161, 49, 0.66);
  font-size: 12px;
`;

const RoundedCircle = styled.div`
  padding: 4px;
  background: #0000004f;
  line-height: 7px;
  cursor: pointer;
  &:hover {
    background: #00000098;
  }
`;

const TimeCheckStyledDiv = styled.span`
  background: #00000036;
  border-radius: 5px;
  padding: 2px;
  margin-left: 8px;
  font-size: 11px;
  letter-spacing: 0.04rem;
  &:hover {
    background: #000000a1;
  }
`;

const StyledCard = styled.div`
  cursor: pointer;
  // width: 15rem;
  font-size: 12px;
  padding-top: 0px;
  min-height: 211px;
  border: #af97d8 solid 3px;
  img {
    width: 50%;
  }

  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    border: #b2d897 solid 3px;

    div > svg {
      color: #b2d897;
    }
  }
`;
const StyledDescriptionDiv = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
const Card = ({
  name,
  type,
  description,
  email,
  smsNumber,
  whatsAppNumber,
  uid,
  active = false,
  setNewGroup,
  setVisibleLeft,
  canRotaPadUserUpdate = false,
}) => {
  const {
    deleteDoc,
    setRotaPadInvitations,
    fetchData,
    database,
    setRotaPadAbsences,
    setRotaPadWorkOrders,
    setRotaPadWorkSpaces,
    setRotaPadGroups,
  } = useApiService();
  const navigate = useNavigate();

  const handleClick = useCallback(async () => {
    if (
      await simpleConfirm({
        title: '🗑️ Delete Integrations',
        message: `Do you want to delete "${name}"?`,
        confirmLabel: 'Delete',
        cancelLabel: 'Cancel',
      })
    ) {
      console.log('Confirmed! ;)');
      const DeleteGroup = await deleteDoc({
        db: database,
        path: 'integrations/' + uid,
      });
    } else {
      console.log('Not confirmed. 🥲');
    }

    // const fetchGroups = await fetchData({
    // 	db: database,
    // 	path: "groups",
    // });

    // // console.log("absences ===== ", fetchGroups);
    // if (fetchGroups) {
    // 	setRotaPadGroups(fetchGroups);
    // }
  }, [
    database,
    deleteDoc,
    fetchData,
    setRotaPadGroups,
    uid,
    simpleConfirm,
    name,
  ]);
  return (
    <>
      <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
        <PageCard className='card shadow-sm mb-3 '>
          <div className='card-header  bg-transparent pt-3'>
            <div
              className='text-uppercase fw-semibold'
              style={{
                fontSize: '11px',
                color: 'rgb(141, 141, 142)',
                marginBottom: '-4px',
                marginTop: '-4px',
                lineHeight: '12px',
              }}
            >
              <Pill
                content={type}
                bgStyle={{ ...BADGE_STYLE[`${type}`] }}
                toolTipMessage='Integration type'
              />
              <Pill
                content={active ? 'Active' : 'Inactive'}
                bgStyle={{
                  ...(active ? BADGE_STYLE.Active : BADGE_STYLE.Inactive),
                }}
                toolTipMessage='Email'
              />
            </div>
            <div
              className='text-uppercase fw-bold mt-2'
              style={{
                fontSize: '14px',
                // color: '#8d95d2',
              }}
            >
              <div className='mb-1  text-truncate'>
                <TbMessage2Plus
                  style={{ marginRight: '4px', marginTop: '-4px' }}
                />
                {name}
              </div>
            </div>
          </div>
          <div className='card-body '>
            {email && (
              <Pill
                content={email}
                bgStyle={{ ...BADGE_STYLE.Admin }}
                toolTipMessage='Email'
              />
            )}
            {smsNumber && (
              <Pill
                content={smsNumber}
                bgStyle={{ ...BADGE_STYLE.Admin }}
                toolTipMessage='SMS number'
              />
            )}
            {whatsAppNumber && (
              <Pill
                content={whatsAppNumber}
                bgStyle={{ ...BADGE_STYLE.Admin }}
                toolTipMessage='WhatsApp number'
              />
            )}

            {description && description !== '' && (
              <StyledDescriptionDiv className='pt-3'>
                {description}
              </StyledDescriptionDiv>
            )}
          </div>
          {canRotaPadUserUpdate && (
            <div className='card-footer bg-transparent '>
              <ToolTip
                placement='top'
                overlay={<span style={{ fontSize: 10 }}>Update</span>}
              >
                <RoundButtonBase
                  variant='outline'
                  onClick={() => {
                    setNewGroup({
                      name,
                      type,
                      email,
                      smsNumber,
                      whatsAppNumber,
                      description,
                      uid,
                      active,
                    });
                    setVisibleLeft(true);
                  }}
                  type='button'
                  size='small'
                  className={`me-2 `}
                >
                  <RiEditLine />
                </RoundButtonBase>
              </ToolTip>
              <ToolTip
                placement='top'
                overlay={<span style={{ fontSize: 10 }}>Delete</span>}
              >
                <RoundButtonBase
                  variant='outline'
                  onClick={handleClick}
                  type='button'
                  size='small'
                  className={` me-2 `}
                >
                  <AiOutlineDelete />
                </RoundButtonBase>
              </ToolTip>
              <SimpleDialogContainer
                primaryColor='#dc3545'
                primaryHoverColor='#a42531'
              />
            </div>
          )}
        </PageCard>
      </div>
    </>
  );
};

export default Card;
