import styled from '@emotion/styled';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { Form } from 'react-final-form';
import { generate as generateUUID } from 'short-uuid';
import { TOAST_TYPE } from '../../common/constants';
import { useToast } from '../../common/hooks/useToast';
import { useApiService } from '../../context';
import AddInvitationForm from '../AddInvitationForm';
import axios from 'axios';
import {
  StyledFooterDiv,
  StyledHeaderDiv,
} from '../../customers/AddCustomerFormContainer';
import { ButtonBase } from '../../common';
import { useSideBarClickAway } from '../../common/hooks/useSideBarClickAway';

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
`;
const StyledDiv = styled.div`
  right: 12px !important;
  top: 42px !important;

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;

const AddInvitationFormContainer = ({
  visibleLeft,
  setVisibleLeft,
  auth,
  newGroup,
  setNewGroup,
  users,
}) => {
  const {
    createDoc,
    database,
    rotaPadUser,
    setRotaPadGroups,
    fetchData,
    rotaPadGroups,
    setRotaPadInvitations,
    rotaPadInvitations,
    rotaPadTeamMembersTopic,
    rotaPadInvitesTopic,
  } = useApiService();
  const { triggerToast } = useToast();
  const ref = useRef(null);
  useSideBarClickAway({
    ref,
    setVisibleLeft: () => setVisibleLeft(false),
    setNewGroup: () => setNewGroup({}),
  });

  const validate = (data) => {
    let errors = {};
    const invites = _.isObject(rotaPadInvitesTopic)
      ? Object.keys(rotaPadInvitesTopic).map((key) => rotaPadInvitesTopic[key])
      : [];

    const teamMembers = _.isObject(rotaPadTeamMembersTopic)
      ? Object.keys(rotaPadTeamMembersTopic).map(
          (key) => rotaPadTeamMembersTopic[key]
        )
      : [];

    if (!data.firstname) {
      errors.firstname = 'First Name is required.';
    }

    if (!data.lastname) {
      errors.lastname = 'Last Name is required.';
    }

    if (!newGroup.uid) {
      if (!data.email) {
        errors.email = 'Email is required.';
      }

      if (
        data.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = 'Invalid email address. E.g. example@email.com';
      }

      if (data.email && _.find(teamMembers, { email: data.email })) {
        errors.email = 'User exists in this workspace';
      }

      if (data.email && _.find(invites, { email: data.email })) {
        errors.email = 'User has a pending invite';
      }
    }

    if (!data.role) {
      errors.role = 'Role is required.';
    }

    if (!data.group) {
      errors.group = 'Group is required.';
    }

    return errors;
  };

  const handleSubmitForm = useCallback(
    async (data, form) => {
      // const { name, handle, description } = data;
      console.log('data ====== ', data);
      const uuid = data.uid ? data.uid : generateUUID();

      const newGroup = {
        ...data,
        uid: uuid,
      };
      if (rotaPadUser) {
        const success = await createDoc(database, `invites/${uuid}`, newGroup);

        // const fetchGroups = await fetchData({
        // 	db: database,
        // 	path: "invitations",
        // });

        // // console.log("invitations ===== ", fetchGroups);
        // if (fetchGroups) {
        // 	setRotaPadInvitations(fetchGroups);
        // }

        if (success) {
          await axios.post(
            'https://api.sendinblue.com/v3/smtp/email',
            {
              sender: { name: 'RotaPad', email: 'noreply@rotapad.com' },
              to: [
                {
                  email: data.email,
                  name: data.firstname + ' ' + data.lastname,
                },
              ],
              subject: 'WestBrand Design Workspace Invitation',
              htmlContent: `<!doctype html>
                <html>
                  <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                    <title>RotaPad Invite Email</title>
                    <style>
                @media only screen and (max-width: 620px) {
                  table.body h1 {
                    font-size: 28px !important;
                    margin-bottom: 10px !important;
                  }
                
                  table.body p,
                table.body ul,
                table.body ol,
                table.body td,
                table.body span,
                table.body a {
                    font-size: 16px !important;
                  }
                
                  table.body .wrapper,
                table.body .article {
                    padding: 10px !important;
                  }
                
                  table.body .content {
                    padding: 0 !important;
                  }
                
                  table.body .container {
                    padding: 0 !important;
                    width: 100% !important;
                  }
                
                  table.body .main {
                    border-left-width: 0 !important;
                    border-radius: 0 !important;
                    border-right-width: 0 !important;
                  }
                
                  table.body .btn table {
                    width: 100% !important;
                  }
                
                  table.body .btn a {
                    width: 100% !important;
                  }
                
                  table.body .img-responsive {
                    height: auto !important;
                    max-width: 100% !important;
                    width: auto !important;
                  }
                }
                @media all {
                  .ExternalClass {
                    width: 100%;
                  }
                
                  .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                    line-height: 100%;
                  }
                
                  .apple-link a {
                    color: inherit !important;
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;
                    text-decoration: none !important;
                  }
                
                  #MessageViewBody a {
                    color: inherit;
                    text-decoration: none;
                    font-size: inherit;
                    font-family: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                  }
                
                  .btn-primary table td:hover {
                    background-color: #34495e !important;
                  }
                
                  .btn-primary a:hover {
                    background-color: #34495e !important;
                    border-color: #34495e !important;
                  }
                }
                </style>
                  </head>
                  <body style="background-color: #f6f6f6; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">This is preheader text. Some clients will show this text as a preview.</span>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6; width: 100%;" width="100%" bgcolor="#f6f6f6">
                      <tr>
                        <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                        <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; margin: 0 auto;" width="580" valign="top">
                          <div class="content" style="box-sizing: border-box; display: block; margin: 0 auto; max-width: 580px; padding: 10px;">
                
                            <!-- START CENTERED WHITE CONTAINER -->
                            <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 3px; width: 100%;" width="100%">
                
                              <!-- START MAIN CONTENT AREA -->
                              <tr>
                                <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                    <tr>
                                      <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;"><img src="http://westbrand.net/wp-content/uploads/2022/02/LOGO-Westbrand-G-300x31.png"  alt="user-img" style="width: 185px; cursor: pointer;"></p>
                                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Hi ${
                                          data.firstname + ' ' + data.lastname
                                        },</p>
                                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">You have been invited to join RotaPad Workspace! <br /><strong>${
                                          data.email
                                        }</strong> has been set as your primary email. Click on the button below to create a new password for your RotaPad account.</p>
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%;" width="100%">
                                          <tbody>
                                            <tr>
                                              <td align="left" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                                                  <tbody>
                                                    <tr>
                                                      <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #3498db;" valign="top" align="center" bgcolor="#3498db"> <a href="https://westbrand.rotapad.com/onboard?_atemail=${
                                                        data.email
                                                      }&_fname=${
                data.firstname
              }&_lname=${
                data.lastname
              }&_uuid=${uuid}" target="_blank" style="border: solid 1px #3498db; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 14px; font-weight: bold; margin: 0; padding: 12px 25px; text-decoration: none; text-transform: capitalize; background-color: #3498db; border-color: #3498db; color: #ffffff;">Join WestBrand Workspace</a> </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Once onboarded, you become part of the workspace. You can also invite other team members to sign up using their email address.</p>
                                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Have a nice day ahead!</p>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                
                            <!-- END MAIN CONTENT AREA -->
                            </table>
                            <!-- END CENTERED WHITE CONTAINER -->
                
                            <!-- START FOOTER -->
                            <div class="footer" style="clear: both; margin-top: 10px; text-align: center; width: 100%;">
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                <tr>
                                  <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                    <span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;">Rota Pad Services Inc. T2Z 1J9, Calgary AB.</span>
                                    <br> Do you want fnd out more? <a href="https://rotapad.com" style="text-decoration: underline; color: #999999; font-size: 12px; text-align: center;">RotaPad.com</a>.
                                  </td>
                                </tr>
                                <tr>
                                  <td class="content-block powered-by" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                    Powered by <a href="https://rotapad.com" style="color: #999999; font-size: 12px; text-align: center; text-decoration: none;">RotaPad APIs</a>.
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <!-- END FOOTER -->
                
                          </div>
                        </td>
                        <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                      </tr>
                    </table>
                  </body>
                </html>`,
              headers: {
                charset: 'iso-8859-1',
              },
            },
            {
              headers: {
                'api-key': `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`,
              },
            }
          );

          triggerToast({
            message: 'Invite successfully sent!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Invite could not be sent!',
            type: TOAST_TYPE.warning,
          });
        }

        form.reset();
        form.resetFieldState('email');
        form.resetFieldState('firstname');
        form.resetFieldState('lastname');

        form.resetFieldState('role');
        form.resetFieldState('group');

        setNewGroup({});
        setVisibleLeft(false);
      }
    },
    [
      createDoc,
      database,
      fetchData,
      rotaPadUser,
      setNewGroup,
      setRotaPadInvitations,
      setVisibleLeft,
      triggerToast,
    ]
  );

  // console.log("AddInvitationFormContainer newGroup ==== ", newGroup);
  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={newGroup}
      validate={validate}
      render={({
        handleSubmit,
        pristine,
        submitting,
        hasValidationErrors,
        form,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div
              // ref={ref}
              className={`offcanvas offcanvas-end  ${
                visibleLeft ? 'show shadow-lg' : 'hiding'
              }`}
              // tabIndex='-1'
              id='offcanvasExample'
              aria-labelledby='offcanvasExampleLabel'
              style={{ width: '24em', zIndex: 555 }}
            >
              <StyledHeaderDiv
                className='offcanvas-header'
                // style={{ backgroundColor: '#f5ebff' }}
              >
                <h5
                  className='offcanvas-title'
                  style={{ lineHeight: '27px' }}
                >
                  Send an invite
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                  onClick={() => {
                    setVisibleLeft(false);
                    setNewGroup({});
                    form.reset();
                    form.resetFieldState('email');
                    form.resetFieldState('firstname');
                    form.resetFieldState('lastname');

                    form.resetFieldState('role');
                    form.resetFieldState('group');
                    // setNewGroup({});
                  }}
                ></button>
              </StyledHeaderDiv>
              <div
                style={{ maxHeight: 'calc(100vh - 118px)', overflow: 'auto' }}
              >
                <div className='offcanvas-body p-0'>
                  <div
                    className='d-flex flex-column mb-0'
                    style={{ height: '100vh' }}
                  >
                    <div className='col-12 mb-1 text-muted px-3 pt-3'>
                      Invite others to join your workspace. You can also allow
                      members to sign up using your company’s email domain.
                    </div>
                    <AddInvitationForm
                      hasValidationErrors={hasValidationErrors}
                      submitting={submitting}
                      pristine={pristine}
                      handleSubmit={handleSubmit}
                      visibleLeft={visibleLeft}
                      uid={newGroup.uid}
                    />
                  </div>
                </div>
              </div>

              <StyledFooterDiv
                className='offcanvas-header'
                // style={{ backgroundColor: '#f5ebff' }}
              >
                <div className='btn-toolbar my-2 my-sm-0 my-md-0'>
                  <div className='d-flex flex-row mb-0'>
                    <div className='me-2'>
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='small'
                        onClick={() => {
                          setVisibleLeft(false);
                          setNewGroup({});
                          form.reset();
                          form.resetFieldState('email');
                          form.resetFieldState('firstname');
                          form.resetFieldState('lastname');

                          form.resetFieldState('role');
                          form.resetFieldState('group');
                          // setNewGroup({});
                        }}
                      >
                        Cancel
                      </ButtonBase>
                    </div>
                    <div>
                      <ButtonBase
                        type='submit'
                        variant='primary'
                        size='small'
                        disabled={pristine || submitting || hasValidationErrors}
                      >
                        {`${newGroup.uid ? 'Update' : 'Send'} invite`}
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </StyledFooterDiv>
            </div>
          </form>
        );
      }}
    />
  );
};

export default AddInvitationFormContainer;
