import KeyMirror from 'keymirror';

export const ROUTES = KeyMirror({
  '/months': null,
  '/days': null,
  '/reports': null,
  '/team': null,
  '/profile': null,
  '/absences': null,
  '/request': null,
  '/group': null,
  '/invitation': null,
  '/customers': null,
});

export const USER_TYPES = KeyMirror({
  Admin: null,
  User: null,
  Support: null,
});

export const ABILITY_TYPES = KeyMirror({
  read: null,
  update: null,
  delete: null,
});

export const URLS = {
  months: '/months',
  days: '/days',
  reports: '/reports',
  customers: '/customers',
  team: '/team',
  profile: '/profile',
  absences: '/absences',
  request: '/request',
  groups: '/groups',
  invitations: '/invitations',
  dashboard: '/dashboard',
  workflows: '/workflows',
  approve: '/approve',
  onboard: '/onboard',
  integrations: '/integrations',
};

export const TITLES = {
  '/dashboard': 'Shifts',
  '/team': 'Team Members',
  '/groups': 'User Groups',
  '/absences': 'Absences',
  '/customers': 'Customers',
  '/reports': 'Reports',
  '/profile': 'My Profile',
  '/integrations': 'Integrations',
};

export const SHIFTS = 'shifts';

export const CARD_VIEW_TYPE = 'card';

export const TABLE_VIEW_TYPE = 'table';

export const MAP_VIEW_TYPE = 'map';

export const TOAST_TYPE = {
  info: 'info',
  error: 'error',
  warning: 'warning',
  success: 'success',
};

export const BADGE_STYLE = {
  // Leave requests
  Vacation: { backgroundColor: '#23b7e5' },
  'Personal Time Off': { backgroundColor: '#65bc34' },
  'Unpaid Vacation': { backgroundColor: '#047a9e' },
  'Time in lieu': { backgroundColor: '#ff006e' },
  'Working Remotely': { backgroundColor: '#ff6300' },
  Conference: { backgroundColor: '#8846b5' },
  'Stats Holiday': { backgroundColor: '#00000094' },

  // Priority
  Highest: { backgroundColor: '#c32c0a99' },
  High: { backgroundColor: '#f44b25' },
  Medium: { backgroundColor: '#ffac00' },
  Lowest: { backgroundColor: '#0065ff' },
  Low: { backgroundColor: '#2784ff' },

  // Status
  'To Do': { backgroundColor: '#78797b' },
  'On Hold': { backgroundColor: '#314159a3' },
  'In Progress': { backgroundColor: '#0748a7a8' },
  Done: { backgroundColor: '#2da966' },

  //Tags
  External: { backgroundColor: '#00b2d6' },

  //TimeEntry
  Time: { backgroundColor: '#00b2d6' },

  //SideMenu
  Menu: { backgroundColor: '#521aac70' },

  //Workspaces
  Event: { backgroundColor: '#00b2d6' },
  Shift: { backgroundColor: '#fbbf0c' },
  Workorder: { backgroundColor: '#4f46e5' },

  //Group
  Group: { backgroundColor: '#65bc34' },

  //Role
  Admin: { backgroundColor: '#0f7bc0' },
  User: { backgroundColor: '#42a2e0' },
  Support: { backgroundColor: '#07446b' },
  //Workflows
  Website: { backgroundColor: '#8f1fff' },
  WhatsApp: { backgroundColor: '#0ea761' },
  Active: { backgroundColor: '#65bc34' },
  Inactive: { backgroundColor: '#43434399' },
  SMS: { backgroundColor: '#3783f3' },
  Email: { backgroundColor: '#e53197' },

  //Group
  Customer: { backgroundColor: '#e39011', marginBottom: '3px' },
  Unit: { backgroundColor: '#d95900', marginBottom: '3px' },
};

export const REQUEST_TYPES = [
  {
    name: 'Vacation',
    value: 'Vacation',
  },
  {
    name: 'Personal Time Off',
    value: 'Personal Time Off',
  },
  {
    name: 'Unpaid Vacation',
    value: 'Unpaid Vacation',
  },
  {
    name: 'Time in lieu',
    value: 'Time in lieu',
  },
  {
    name: 'Working Remotely',
    value: 'Working Remotely',
  },
  {
    name: 'Conference',
    value: 'Conference',
  },
  {
    name: 'Stats Holiday',
    value: 'Stats Holiday',
  },
];
