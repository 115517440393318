import { ButtonBase, PageTitleBar } from '../../common';
import {
  ABILITY_TYPES,
  BADGE_STYLE,
  TITLES,
  URLS,
  USER_TYPES,
} from '../../common/constants';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { useShiftService } from '../ShiftContext';
import StarterImg from '../../common/img/explore.png';
import Card from '../Card';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApiService } from '../../context';
import { convertObjectToArray } from '../../common/utils';
import ShiftsTable from '../Table';
import { AbilityContext } from 'components/context/ability-context';
import { useAbility } from '@casl/react';

const MonthlyShiftsContainer = ({ setVisibleLeft, setNewGroup, newGroup }) => {
  const {
    rotaCurrentDate,
    setRotaCurrentDate,
    rotaPadShiftsTopic,
    // isRotaPadUserAdmin,
  } = useShiftService();
  const { isRotaPadUserAdmin, rotaPadCustomersTopic, rotaPadUser } =
    useApiService();
  const ability = useAbility(AbilityContext);
  const [isCardView, setIsCardView] = useState(true);

  const [selectedFilterOptions, setSelectedFilterOptions] = useState([
    { name: 'My tasks', code: 'myshifts' },
    { name: 'Assigned', code: 'assigned' },
    { name: 'Unassigned', code: 'unassigned' },
    ,
  ]);

  useEffect(() => {
    if (
      isRotaPadUserAdmin ||
      (rotaPadUser && rotaPadUser.role === USER_TYPES.Support)
    ) {
      setSelectedFilterOptions([
        { name: 'My tasks', code: 'myshifts' },
        { name: 'Assigned', code: 'assigned' },
        { name: 'Unassigned', code: 'unassigned' },
        ,
      ]);
    }
  }, [isRotaPadUserAdmin, rotaPadUser]);
  const filter = {
    unassigned: (attendees) => attendees.length === 0,
    assigned: (attendees) => attendees.length > 0,
    myshifts: (attendees, userEmail) =>
      _.find(attendees, {
        email: userEmail,
      }),
  };

  const getCurrentDate = useMemo(() => {
    return `Tasks / ${moment(rotaCurrentDate)
      .format('MMMM')
      .toString()} ${moment(rotaCurrentDate).format('YYYY').toString()}`;
  }, [rotaCurrentDate]);

  const handleClickNext = useCallback(() => {
    setRotaCurrentDate(
      moment(rotaCurrentDate).add(1, 'months').format().toString()
    );
  }, [rotaCurrentDate]);

  const handleClickPrev = useCallback(() => {
    setRotaCurrentDate(
      moment(rotaCurrentDate).subtract(1, 'months').format().toString()
    );
  }, [rotaCurrentDate]);

  const handleClickAdd = useCallback(() => {
    setNewGroup({
      start: '',
      end: '',
      title: '',
      description: '',
      type: 'Long Day',
      customer: '',
      status: 'To Do',
      attendees: [],
      // id: uuidv4(),
      uid: undefined,
      createdAt: moment().format(),
    });
    setVisibleLeft(true);
  }, [setNewGroup, setVisibleLeft]);

  const canRotaPadUserUpdate = useMemo(
    () =>
      ability &&
      ability.can(ABILITY_TYPES.read, TITLES[`${URLS.dashboard}`]) &&
      ability.can(ABILITY_TYPES.update, TITLES[`${URLS.dashboard}`]),
    [ability]
  );

  const getFilterActions = useMemo(
    () =>
      _.pick(
        filter,
        selectedFilterOptions.map((x) => x.code)
      ),
    [selectedFilterOptions, filter]
  );

  const getFilteredShifts = useMemo(() => {
    // const filter = {
    //   unassigned: (attendees) => attendees.length === 0,
    //   assigned: (attendees) => attendees.length > 0,
    //   myshifts: (attendees, userEmail) =>
    //     _.find(attendees, {
    //       email: userEmail,
    //     }),
    // };

    let result = convertObjectToArray({ item: rotaPadShiftsTopic });
    let as = [];
    let un = [];
    let ms = [];
    if (getFilterActions.assigned !== undefined) {
      as = result.filter(({ attendees = [] }) => attendees.length > 0);
    }

    if (getFilterActions.unassigned !== undefined) {
      un = result.filter(({ attendees = [] }) => attendees.length === 0);
    }

    if (getFilterActions.myshifts !== undefined) {
      ms = result.filter(({ attendees = [] }) =>
        _.find(attendees, { email: rotaPadUser.email })
      );
    }

    return _.isEmpty(getFilterActions)
      ? result
      : [...new Set([...as, ...un, ...ms])];
  }, [rotaPadShiftsTopic, getFilterActions, rotaPadUser]);
  // console.log('getFilterActions ==== ', getFilterActions);
  return (
    <div className='container-fluid mx-0 px-0'>
      <div className='row'>
        <div className='col-12 mt-0'>
          {/* <StyledHeaders>Recommended templates</StyledHeaders>
    <StyledTemplatesDiv className='d-flex flex-row mt-2 mb-4 px-3 pt-3 pb-2'>
      {WORKFLOW_TEMPLATES.map((obj, index) => {
        const { title, description, icon, image } = obj;
        return (
          <div
            className='me-3'
            key={index}
            onClick={() => setVisibleLeft(true)}>
            <StyledCardContainer>
              <StyledCard className='card shadow-sm'>
                <div className='text-center'>
                  {icon}
                  <img src={image} />
                </div>

                <div className='card-body pt-1'>
                  <p className='card-text'>{description}</p>
                </div>
              </StyledCard>
              <span>{title}</span>
            </StyledCardContainer>
          </div>
        );
      })}
    </StyledTemplatesDiv> */}
          {/* {`Shifts / `}
          {`${moment(currentDate).format('MMMM').toString()} ${moment(
            currentDate
          )
            .format('YYYY')
            .toString()}`} */}
          <PageTitleBar
            title={getCurrentDate}
            handleClickNext={handleClickNext}
            handleClickPrev={handleClickPrev}
            handleClickAdd={handleClickAdd}
            isCardView={isCardView}
            setIsCardView={setIsCardView}
            canRotaPadUserUpdate={canRotaPadUserUpdate}
            filtered={true}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}

            // count={_.isObject(shifts) ? Object.keys(shifts).length : 0}
            // badgeStyle={BADGE_STYLE.Menu}
          />

          {_.isObject(rotaPadShiftsTopic) &&
          Object.keys(rotaPadShiftsTopic).length > 0 ? (
            <div className='container-fluid py-2 px-0'>
              <div className='row g-3 '>
                {!isCardView && (
                  <ShiftsTable
                    shifts={_.sortBy(getFilteredShifts, ['start'])}
                    setShiftObj={setNewGroup}
                    shiftObj={newGroup}
                    setShiftVisibleRight={setVisibleLeft}
                    isUserAdmin={canRotaPadUserUpdate}
                    customers={convertObjectToArray({
                      item: rotaPadCustomersTopic,
                    })}
                  />
                )}

                {isCardView && (
                  <>
                    {_.sortBy(getFilteredShifts, ['start']).map((key) => {
                      return (
                        <Card
                          key={key.uid}
                          setNewGroup={setNewGroup}
                          setVisibleLeft={setVisibleLeft}
                          canRotaPadUserUpdate={canRotaPadUserUpdate}
                          {...key}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className='card mt-3 text-center'>
                <div className='pt-4 pb-2 text-muted'>
                  <img src={StarterImg} />
                </div>
                <div className='d-flex justify-content-center px-4 mb-2'>
                  <div className='text-muted w-50'>
                    <div className='pb-4'>
                      Easily create shifts and working times. You can also
                      assign team members to each shift.
                    </div>
                    {isRotaPadUserAdmin !== undefined && isRotaPadUserAdmin && (
                      <div className='pt-4 mb-4'>
                        <ButtonBase
                          type='button'
                          variant='primary'
                          size='default'
                          className='w-50'
                          onClick={handleClickAdd}
                        >
                          <i
                            className='pi pi-user-plus'
                            style={{
                              fontSize: '12px',
                              marginRight: '4px',
                            }}
                          ></i>
                          {`Add a shift for ${moment(rotaCurrentDate)
                            .format('MMMM')
                            .toString()} ${moment(rotaCurrentDate)
                            .format('YYYY')
                            .toString()}`}
                        </ButtonBase>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MonthlyShiftsContainer;
