import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { BsPeople } from 'react-icons/bs';
import { ButtonBase, PageTitleBar } from '../common';
import { ABILITY_TYPES, BADGE_STYLE, TITLES, URLS } from '../common/constants';
import { useApiService } from '../context'; //theme
import AddIntegrationsFormContainer from './AddIntegrationsFormContainer';
import StarterImg from '../common/img/explore.png';
import Card from './Card';
import { AbilityContext } from 'components/context/ability-context';
import { useAbility } from '@casl/react';

const Integrations = () => {
  const { rotaPadUser, auth, rotaPadIntegrationsTopic } = useApiService();
  const ability = useAbility(AbilityContext);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [newGroup, setNewGroup] = useState({
    type: '',
    name: '',
    email: '',
    smsNumber: '',
    whatsAppNumber: '',
    description: '',
    uid: undefined,
    active: false,
    createdAt: moment().format(),
  });

  const canRotaPadUserUpdate = useMemo(
    () =>
      ability &&
      ability.can(ABILITY_TYPES.read, TITLES[`${URLS.integrations}`]) &&
      ability.can(ABILITY_TYPES.update, TITLES[`${URLS.integrations}`]),
    [ability, rotaPadUser]
  );

  // console.log('rotaPadIntegrationsTopic ===== ', rotaPadIntegrationsTopic);
  // console.log('rotaPadGroups ===== ', rotaPadGroups);
  if (!rotaPadUser) return null;

  // console.log("rotaPadGroups ==== ", rotaPadGroups);
  return (
    <div className='container-fluid mx-0 px-0'>
      <div className='row'>
        <div className='col-12 mt-0'>
          <AddIntegrationsFormContainer
            visibleLeft={visibleLeft}
            setVisibleLeft={setVisibleLeft}
            auth={auth}
            newGroup={newGroup}
            setNewGroup={setNewGroup}
          />

          <PageTitleBar
            title='Unified Messaging Integrations'
            count={
              _.isObject(rotaPadIntegrationsTopic)
                ? Object.keys(rotaPadIntegrationsTopic).length
                : `0`
            }
            badgeStyle={BADGE_STYLE.Menu}
            handleClickAdd={() => setVisibleLeft(true)}
            canRotaPadUserUpdate={canRotaPadUserUpdate}
          />
          {_.isObject(rotaPadIntegrationsTopic) &&
          Object.keys(rotaPadIntegrationsTopic).length > 0 ? (
            <div className='container-fluid py-2 px-0'>
              <div className='row g-3 '>
                {Object.keys(rotaPadIntegrationsTopic).map((key) => {
                  return (
                    <Card
                      key={key}
                      setNewGroup={setNewGroup}
                      setVisibleLeft={setVisibleLeft}
                      canRotaPadUserUpdate={canRotaPadUserUpdate}
                      {...rotaPadIntegrationsTopic[key]}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              <div className='card mt-3 text-center'>
                <div className='pt-4 pb-2 text-muted'>
                  <img src={StarterImg} />
                </div>
                <div className='d-flex justify-content-center px-4 mb-2'>
                  <div className='text-muted w-50'>
                    <div className=''>
                      Connect your dashboard to incoming SMS, WhatsApp and Email
                      messages.
                    </div>
                    <div className='pt-4 mb-4'>
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='default'
                        className='w-50'
                        onClick={() => setVisibleLeft(true)}
                      >
                        <i
                          className='pi pi-user-plus'
                          style={{
                            fontSize: '12px',
                            marginRight: '4px',
                          }}
                        ></i>
                        Add new integrations
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
