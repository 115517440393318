import styled from '@emotion/styled';
import moment from 'moment';
import { useCallback } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOpenInNew } from 'react-icons/md';
import { RiEditLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Card as PageCard, Pill, RoundButtonBase, ToolTip } from '../../common';
import { BADGE_STYLE, TOAST_TYPE } from '../../common/constants';
import { useApiService } from '../../context';
import { useShiftService } from '../ShiftContext';
import {
  convertObjectToArray,
  getShiftNotificationBody,
} from '../../common/utils';
import _ from 'lodash';
import { BsPeople } from 'react-icons/bs';
import { BiStopwatch, BiUser } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa';
import {
  FiAlertOctagon,
  FiAlertTriangle,
  FiCheck,
  FiCheckSquare,
  FiX,
} from 'react-icons/fi';
import { useToast } from 'components/common/hooks/useToast';
import TimeEntry from './TimeEntry';
import TimeEntryContainer from './TimeEntryContainer';
import CustomerApprovalContainer from './CustomerApprovalContainer';
import { SimpleDialogContainer, simpleConfirm } from 'react-simple-dialogs';
import axios from 'axios';

const RoundedPill = styled.div`
  padding: 7px;
  color: rgb(255, 255, 255);
  background-color: ${({ active }) => (active ? `#387219a8` : `#525252a8`)};
  font-size: 12px;

  div > svg {
    font-size: 0.685rem;
    color: #fff;
  }
`;

const RoundedCircle = styled.div`
  padding: 4px;
  background: #0000004f;
  line-height: 7px;
  cursor: pointer;
  &:hover {
    background: #00000098;
  }
`;

const TimeCheckStyledDiv = styled.span`
  background: #00000036;
  border-radius: 5px;
  padding: 2px;
  margin-left: 8px;
  font-size: 11px;
  letter-spacing: 0.04rem;
  cursor: ${({ active }) =>
    active !== undefined && active ? 'pointer' : 'default'};
  &:hover {
    background: #000000a1;
  }
`;

const ApprovedStyledDiv = styled.span`
  background: #267500;
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
  color: #fcfcfc;
  border-radius: 5px;
  padding: 2px 3px 2px 3px;
  margin-left: 4px;
  cursor: pointer;
  // font-size: 11px;
  // letter-spacing: 0.04rem;
  cursor: ${({ active }) =>
    active !== undefined && active ? 'pointer' : 'default'};
  &:hover {
    background: #000000a1;
    color: #4bcf0a;
  }
`;

// const ApprovedStyledDiv = styled.span`
//   background: no-repeat;
//   font-size: 14px;
//   line-height: 16px;
//   color: #358511;
//   border-radius: 5px;
//   padding: 0px 3px 3px 3px;
//   margin-left: 4px;
//   // font-size: 11px;
//   // letter-spacing: 0.04rem;
//   cursor: ${({ active }) =>
//     active !== undefined && active ? 'pointer' : 'default'};
//   &:hover {
//     background: #000000a1;
//     color: #52e601;
//   }
// `;

const StyledCard = styled.div`
  cursor: pointer;
  font-size: 12px;
  padding-top: 0px;
  min-height: 211px;
  border: transparent solid 3px;
  img {
    width: 70px;
  }

  div > svg {
    font-size: 34px;
    color: ${(props) => props.theme[`primary-200`]};
  }
  &:hover {
    border: ${(props) => props.theme[`primary-100`]} solid 3px;

    div > svg {
      color: ${(props) => props.theme.cardHoverBorder};
    }
  }

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

// const StyledCard = styled.div`
// 	cursor: pointer;
// 	// width: 15rem;
// 	font-size: 12px;
// 	padding-top: 0px;
// 	min-height: 211px;
// 	border: #af97d8 solid 3px;
// 	img {
// 		width: 50%;
// 	}

// 	div > svg {
// 		font-size: 34px;
// 		color: #af97d8;
// 	}
// 	&:hover {
// 		border: #b2d897 solid 3px;

// 		div > svg {
// 			color: #b2d897;
// 		}
// 	}
// `;
const StyledDescriptionDiv = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
const Card = ({
  title,
  uid,
  createdAt,
  setVisibleLeft,
  setNewGroup,
  customer,
  end,
  start,
  // description,
  type,
  description,
  attendees,
  // reporter,
  // tags,
  status,
  canRotaPadUserUpdate = false,
  // group,
  meta,
  ...rest
}) => {
  const {
    database,
    rotaPadCustomersTopic,
    isRotaPadUserAdmin,
    rotaPadUser,
    createDoc,
  } = useApiService();
  const navigate = useNavigate();
  const {
    // rotaCurrentDate,
    fetchShiftsData,
    setShifts,
    deleteShiftDoc,
    rotaCurrentDate,
    createShifDoc,
  } = useShiftService();
  const { triggerToast } = useToast();
  const handleClick = useCallback(async () => {
    setVisibleLeft(false);
    if (
      await simpleConfirm({
        title: '🗑️ Delete Shift',
        message: `Do you want to delete "${title}"?`,
        confirmLabel: 'Delete',
        cancelLabel: 'Cancel',
      })
    ) {
      console.log('Confirmed! ;)');
      const DeleteGroup = await deleteShiftDoc({
        db: database,
        path: `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
          rotaCurrentDate
        ).format('MMMM')}/${uid}`,
      });

      //send email notification
      if (!_.isEmpty(attendees)) {
        await axios.post(
          'https://api.sendinblue.com/v3/smtp/email',
          getShiftNotificationBody({
            email: attendees[0].email,
            firstname: attendees[0].firstname,
            lastname: attendees[0].lastname,
            shiftName: `${title}, with start date of ${moment(start).format(
              'ddd Do, MMM YYYY'
            )}`,
            subject: 'XynexCare Shift Unassigned',
            verb: 'unassigned',
          }),
          {
            headers: {
              'api-key': `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`,
            },
          }
        );
      }
    } else {
      console.log('Not confirmed. 🥲');
    }

    // const fetchShifts = await fetchShiftsData({
    //   db: database,
    //   path: `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
    //     rotaCurrentDate
    //   ).format('MMMM')}`,
    // });

    // // console.log("absences ===== ", fetchGroups);
    // if (fetchShifts) {
    //   setShifts(fetchShifts);
    // }
  }, [
    database,
    uid,
    rotaCurrentDate,
    fetchShiftsData,
    setShifts,
    deleteShiftDoc,
    title,
    setVisibleLeft,
    attendees,
    title,
    start,
  ]);
  // console.log();

  const mergedSelectOptions = useCallback(
    (id) => {
      let findOption = {};
      let customerName = '';
      let customerId = '';
      let customers = convertObjectToArray({ item: rotaPadCustomersTopic });

      for (let index = 0; index < customers.length; index++) {
        const units = customers[index].units;
        const unit = _.find(units, { id });
        if (unit && !customers[index].disabled) {
          findOption = unit;
          customerName = customers[index].name;
          customerId = customers[index].customerId;
          break;
        }
      }
      return {
        id: findOption.id,
        unitName: findOption.name,
        unitId: findOption.unitId,
        customerName,
        customerId,
      };
    },
    [rotaPadCustomersTopic]
  );

  const handleAddAttendee = useCallback(async () => {
    // console.log('rotaPadUser ==== ', rotaPadUser);
    const { email, firstname, group, role, uid: uuid, lastname } = rotaPadUser;

    //PUT LIMIT HERE
    // if (_.isArray(attendees) && attendees.length === 1) {
    //   triggerToast({
    //     message: 'Shifts can only be assigned to one team member!',
    //     type: TOAST_TYPE.warning,
    //   });

    //   return;
    // }

    if (rotaPadUser && rotaCurrentDate) {
      const success = await createShifDoc(
        database,
        `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
          rotaCurrentDate
        ).format('MMMM')}/${uid}`,
        _.pickBy(
          {
            attendees: [
              ...(attendees ? attendees : []),
              { email, firstname, group, role, uid: uuid, lastname },
            ],
            createdAt,
            customer,
            description,
            end,
            start,
            status,
            title,
            type,
            uid,
            meta,
          },
          _.identity
        )
      );

      if (success) {
        triggerToast({
          message: 'Shift accepted successfully!',
          type: TOAST_TYPE.success,
        });
      } else {
        triggerToast({
          message: 'Shift could not be updated!',
          type: TOAST_TYPE.warning,
        });
      }
    }
  }, [
    title,
    uid,
    createdAt,
    type,
    customer,
    description,
    end,
    start,
    attendees,
    rotaPadUser,
    meta,
  ]);

  const handleRemoveAttendee = useCallback(async () => {
    // console.log('rotaPadUser ==== ', rotaPadUser);
    // console.log('attendees ==== ', attendees);
    const { email } = rotaPadUser;
    if (rotaPadUser && rotaCurrentDate) {
      const success = await createShifDoc(
        database,
        `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
          rotaCurrentDate
        ).format('MMMM')}/${uid}`,
        _.pickBy(
          {
            attendees: attendees.filter((attendee) => attendee.email !== email),
            createdAt,
            customer,
            description,
            end,
            start,
            status,
            title,
            type,
            uid,
            meta,
          },
          _.identity
        )
      );

      if (success) {
        triggerToast({
          message: 'Shift rejected successfully!',
          type: TOAST_TYPE.success,
        });
      } else {
        triggerToast({
          message: 'Shift could not be updated!',
          type: TOAST_TYPE.warning,
        });
      }
    }
  }, [
    title,
    uid,
    createdAt,
    type,
    customer,
    description,
    end,
    start,
    attendees,
    rotaPadUser,
    meta,
  ]);

  const handleAddAttendeeHours = useCallback(
    async (hours) => {
      const {
        email,
        firstname,
        group,
        role,
        uid: uuid,
        lastname,
      } = rotaPadUser;

      if (rotaPadUser && rotaCurrentDate) {
        console.log('hours ==== ', hours);
        const success = await createShifDoc(
          database,
          `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
            rotaCurrentDate
          ).format('MMMM')}/${uid}`,
          _.pickBy(
            {
              attendees: attendees.map((attendee) => {
                if (attendee.email === email) {
                  const newObj = _.omit(attendee, ['approved', 'approvalDate']);
                  attendee = { ...newObj, ...hours };
                  // attendee.approved = false;
                  // attendee.approvalDate = moment().format();
                }
                return attendee;
              }),
              createdAt,
              customer,
              description,
              end,
              start,
              status,
              title,
              type,
              uid,
              meta,
            },
            _.identity
          )
        );

        if (success) {
          triggerToast({
            message: 'Shift successfully created!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Shift could not be created!',
            type: TOAST_TYPE.warning,
          });
        }
      }
    },
    [
      title,
      uid,
      createdAt,
      type,
      customer,
      description,
      end,
      start,
      attendees,
      rotaPadUser,
      meta,
    ]
  );

  const handleAddCustomerApprovalHours = useCallback(
    async (approval) => {
      const {
        email,
        firstname,
        group,
        role,
        uid: uuid,
        lastname,
      } = rotaPadUser;

      if (rotaPadUser && rotaCurrentDate) {
        const success = await createShifDoc(
          database,
          `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
            rotaCurrentDate
          ).format('MMMM')}/${uid}`,
          _.pickBy(
            {
              attendees: attendees.map((attendee) => {
                if (attendee.email === email) {
                  attendee.approved = true;
                  attendee.approvalDate = moment().format();
                }
                return attendee;
              }),
              createdAt,
              customer,
              description,
              end,
              start,
              status,
              title,
              type,
              uid,
              meta,
            },
            _.identity
          )
        );

        if (success) {
          triggerToast({
            message: 'Shift successfully created!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Shift could not be created!',
            type: TOAST_TYPE.warning,
          });
        }
      }
    },
    [
      title,
      uid,
      createdAt,
      type,
      customer,
      description,
      end,
      start,
      attendees,
      rotaPadUser,
      meta,
    ]
  );

  // console.log('attendees ===== ', attendees);
  return (
    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
      <PageCard className='card shadow-sm mb-3 '>
        <div className='card-header bg-transparent pt-3'>
          <div
            className='text-uppercase fw-bold mt-0 mb-2'
            style={{
              fontSize: '14px',
              // color: '#8d95d2',
            }}
          >
            <div className='mb-1  text-truncate'>{title}</div>
          </div>
          <div
            className='text-uppercase fw-semibold text-truncate'
            style={{
              fontSize: '11px',
              color: '#8D8D8E',
              marginBottom: '2px',
              marginTop: '-4px',
              lineHeight: '12px',
            }}
          >
            <span>
              {' '}
              <BiStopwatch
                style={{
                  fontSize: '14px',
                  marginTop: '-2px',
                  marginRight: '2px',
                }}
              />
              {`ST: ${moment(start).format('h:mm a, ddd Do, MMM YYYY')}`}
            </span>

            <span
              className='text-truncate'
              style={{
                color: end ? '#8D8D8E' : 'red',
                display: 'block',
                marginTop: '2px',
              }}
            >
              <BiStopwatch
                style={{
                  fontSize: '14px',
                  marginTop: '-2px',
                  marginRight: '2px',
                }}
              />
              {`ET: ${
                end ? moment(end).format('h:mm a, ddd Do, MMM YYYY') : 'N/A'
              }`}
            </span>
          </div>
        </div>
        <div className='card-body '>
          {mergedSelectOptions(customer).customerName &&
          mergedSelectOptions(customer).unitName ? (
            <>
              <Pill
                content={mergedSelectOptions(customer).customerName}
                bgStyle={{ ...BADGE_STYLE.Customer }}
                toolTipMessage='Customer name'
              />
              <Pill
                content={mergedSelectOptions(customer).unitName}
                bgStyle={{ ...BADGE_STYLE.Unit }}
                toolTipMessage='Unit name'
              />
              {/* <Pill
                  content={mergedSelectOptions(customer).customerId}
                  bgStyle={{ ...BADGE_STYLE.Customer }}
                  toolTipMessage='Customer ID'
                /> */}
              <Pill
                content={mergedSelectOptions(customer).unitId}
                bgStyle={{ ...BADGE_STYLE.Unit }}
                toolTipMessage='Unit Id'
              />
            </>
          ) : (
            <>
              <Pill
                content={meta.integrationType}
                bgStyle={{ ...BADGE_STYLE[`${meta.integrationType}`] }}
                toolTipMessage='Integration type'
              />
              <Pill
                content={customer}
                bgStyle={{ ...BADGE_STYLE.Customer }}
                toolTipMessage='Customer name'
              />
            </>
          )}

          {/* {group} */}
          <StyledDescriptionDiv className='pt-1'>
            {attendees ? (
              <div className='mt-2 mb-2'>
                <div
                  className='d-flex flex-row align-items-center fw-normal'
                  style={{
                    fontSize: '12px',
                    color: attendees.length > 0 ? '#5fa439' : '#a9a9a9',
                  }}
                >
                  <div className='me-2'>
                    <BsPeople
                      // className='pi pi-users'
                      style={{
                        fontSize: '0.985rem',
                        marginTop: '-2px',
                        color: attendees.length > 0 ? '#5fa439' : '#a9a9a9',
                      }}
                    ></BsPeople>
                  </div>
                  <div
                    className='ms-0'
                    style={{
                      paddingBottom: '0px',
                    }}
                  >
                    {`${attendees.length} Assignee${
                      attendees.length > 1 || attendees.length === 0 ? 's' : ''
                    }`}
                  </div>
                </div>
              </div>
            ) : (
              <div className='mt-2 mb-2'>
                <div
                  className='d-flex flex-row align-items-center fw-normal'
                  style={{
                    fontSize: '12px',
                    color: '#a9a9a9',
                  }}
                >
                  <div className='me-2'>
                    <BsPeople
                      // className='pi pi-users'
                      style={{
                        fontSize: '0.985rem',
                        color: '#a9a9a9',
                        marginTop: '-2px',
                      }}
                    ></BsPeople>
                  </div>
                  <div
                    className='ms-0'
                    style={{
                      paddingBottom: '0px',
                    }}
                  >
                    {`0 Assignees`}
                  </div>
                </div>
              </div>
            )}

            {attendees &&
              attendees.length > 0 &&
              attendees.map((attendee) => {
                // console.log('attendee ===== ', attendee);
                return (
                  <RoundedPill
                    className='rounded-pill mt-1 mb-2'
                    role='group'
                    aria-label='Basic outlined example'
                    active={true}
                    key={attendee.uid}
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <div
                        className=' text-truncate'
                        // style={{ maxWidth: '120px' }}
                      >
                        <div className='d-flex flex-row'>
                          <div>
                            <BiUser
                              style={{
                                marginRight: '4px',
                                marginTop: '-2px',
                                // fontSize: '0.85rem',
                              }}
                            />
                          </div>
                          <div
                            className='text-truncate'
                            style={{ maxWidth: '85px' }}
                          >
                            <span className='text-truncate'>
                              {attendee.firstname + ' ' + attendee.lastname}
                            </span>
                          </div>
                          <div>
                            <TimeEntryContainer
                              isRotaPadUserAdmin={isRotaPadUserAdmin}
                              attendee={attendee}
                              rotaPadUser={rotaPadUser}
                              start={start}
                              uid={uid}
                              title={title}
                              handleAddAttendeeHours={handleAddAttendeeHours}
                            />
                          </div>
                          {!attendee?.approved &&
                            attendee?.timeEntry &&
                            attendee?.timeEntry !== 0 && (
                              <CustomerApprovalContainer
                                isRotaPadUserAdmin={isRotaPadUserAdmin}
                                attendee={attendee}
                                rotaPadUser={rotaPadUser}
                                start={start}
                                uid={uid}
                                title={title}
                                handleAddAttendeeHours={
                                  handleAddCustomerApprovalHours
                                }
                                customer={customer}
                              />
                            )}
                          {attendee?.approved !== undefined &&
                            attendee?.approved &&
                            attendee?.timeEntry !== 0 && (
                              <div className='text-truncate me-1'>
                                <ToolTip
                                  placement='top'
                                  overlay={
                                    <span
                                      style={{
                                        fontSize: 10,
                                      }}
                                    >
                                      Customer approved!
                                    </span>
                                  }
                                >
                                  <ApprovedStyledDiv
                                    active={
                                      !isRotaPadUserAdmin &&
                                      attendee.email === rotaPadUser.email
                                    }
                                    // onClick={() => setVisible(true)}
                                  >
                                    {/* <FiCheckSquare
                                      style={
                                        {
                                          // marginRight: '4px',
                                          // marginTop: '-4px',
                                          // fontSize: '0.85rem',
                                        }
                                      }
                                    /> */}
                                    APPROVED
                                  </ApprovedStyledDiv>
                                </ToolTip>
                              </div>
                            )}
                        </div>
                      </div>

                      <div>
                        {!isRotaPadUserAdmin &&
                          attendee.email === rotaPadUser.email && (
                            <>
                              {attendee?.approved !== undefined &&
                              attendee?.approved ? (
                                <></>
                              ) : (
                                <ToolTip
                                  placement='top'
                                  overlay={
                                    <span style={{ fontSize: 10 }}>
                                      Remove from shift
                                    </span>
                                  }
                                >
                                  <RoundedCircle
                                    className='rounded-circle'
                                    {...(attendee.email === rotaPadUser.email
                                      ? { onClick: handleRemoveAttendee }
                                      : {})}
                                    // onClick={handleRemoveAttendee}
                                  >
                                    <FiX
                                      style={
                                        {
                                          // marginRight: '4px',
                                          // marginTop: '-4px',
                                          // fontSize: '0.85rem',
                                        }
                                      }
                                    />
                                  </RoundedCircle>
                                </ToolTip>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </RoundedPill>
                );
              })}

            {(!attendees ||
              (attendees &&
                attendees.length > 0 &&
                !_.find(attendees, { email: rotaPadUser.email }))) &&
              !isRotaPadUserAdmin && (
                <RoundedPill
                  className='rounded-pill mt-1 mb-2'
                  role='group'
                  aria-label='Basic outlined example'
                  active={false}
                  // key={attendee.uid}
                >
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className=''>
                      <div className='d-flex flex-row'>
                        <div>
                          <BiUser
                            style={{
                              marginRight: '4px',
                              marginTop: '-2px',
                              // fontSize: '0.85rem',
                            }}
                          />
                        </div>
                        <div
                          className='text-truncate'
                          style={{ maxWidth: '85px' }}
                        >
                          <span className='text-truncate'>{`${rotaPadUser.firstname} ${rotaPadUser.lastname}`}</span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <ToolTip
                        placement='top'
                        overlay={
                          <span style={{ fontSize: 10 }}>Add to shift</span>
                        }
                      >
                        <RoundedCircle
                          className='rounded-circle'
                          onClick={handleAddAttendee}
                        >
                          <FiCheck
                            style={
                              {
                                // marginRight: '4px',
                                // marginTop: '-4px',
                                // fontSize: '0.85rem',
                              }
                            }
                          />
                        </RoundedCircle>
                      </ToolTip>
                    </div>
                  </div>
                </RoundedPill>
              )}
          </StyledDescriptionDiv>
        </div>

        {canRotaPadUserUpdate && (
          <div className='card-footer bg-transparent '>
            <ToolTip
              placement='top'
              overlay={<span style={{ fontSize: 10 }}>Update</span>}
            >
              <RoundButtonBase
                variant='outline'
                onClick={() => {
                  setNewGroup({
                    title,
                    uid,
                    createdAt,
                    type,
                    customer,
                    description,
                    end,
                    start,
                    attendees,
                    status,
                    meta,
                    ...{ ...rest },
                  });
                  setVisibleLeft(true);
                }}
                type='button'
                size='small'
                className={` me-2 `}
              >
                <RiEditLine />
              </RoundButtonBase>
            </ToolTip>
            <ToolTip
              placement='top'
              overlay={<span style={{ fontSize: 10 }}>Delete</span>}
            >
              <RoundButtonBase
                type='button'
                variant='outline'
                size='small'
                onClick={handleClick}
                className={` me-2 `}
              >
                <AiOutlineDelete />
              </RoundButtonBase>
            </ToolTip>
            <SimpleDialogContainer
              primaryColor='#dc3545'
              primaryHoverColor='#a42531'
            />
          </div>
        )}
      </PageCard>
    </div>
  );
};

export default Card;
