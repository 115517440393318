import { getInstanceByDom, init } from 'echarts';
import React, { memo, useEffect, useRef } from 'react';

const Chart = ({
  option,
  style,
  settings,
  loading,
  handleOnClick,
  handleLabelOnClick,
  handleLegendOnClick,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Initialize chart
    let chart;
    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  });

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart.setOption(option, settings);
      chart.on('click', function (params) {
        // console.log(params);
        if (params.targetType === 'axisLabel') {
          handleLabelOnClick(params.dataIndex);
          handleOnClick(null);
        } else {
          handleOnClick({
            color: params.color,
            dataIndex: params.dataIndex,
            name: params.name,
            seriesName: params.seriesName,
          });
          handleLabelOnClick(null);
        }
      });

      chart.on('legendselectchanged', function (params) {
        // State if legend is selected.
        // var isSelected = params.selected[params.name];
        // // print in the console.
        // console.log(
        //   (isSelected ? 'Selected' : 'Not Selected') + 'legend' + params.name
        // );
        // print for all legends.
        // console.log(params.selected);

        // handleLabelOnClick(params.selected);
        // handleLabelOnClick(null);
        // handleOnClick(null);
        handleLegendOnClick(params.selected);
      });
    }
  }, [
    handleOnClick,
    option,
    settings,
    handleLabelOnClick,
    handleLegendOnClick,
  ]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading]);

  return (
    <div
      ref={chartRef}
      style={{ width: '100%', height: '100%', ...style }}
    />
  );
};

export default memo(Chart);
