import styled from '@emotion/styled';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { REQUEST_TYPES } from '../../common/constants';
import { useApiService } from '../../context';

export const StyledInput = styled.div`
  input {
    padding: 8px 8px;
    font-size: 12px;
    width: 100% !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    color: #495057;
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    margin: 0;
    width: 100vh !important;

    &:hover {
      border-color: #6366f1;
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #c7d2fe;
      border-color: #6366f1;
    }
    &:disabled {
      background-color: #e9ecef;
      opacity: 0.6;
      &:hover {
        border-color: #ced4da;
      }
    }
  }
`;

const StyledInputTextArea = styled.textarea`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;

const AddAbsencesForm = ({
  handleSubmit,
  _id,
  pristine,
  submitting,
  hasValidationErrors,
  visibleLeft,
}) => {
  const { rotaPadTeam } = useApiService();
  const [showNewPassword, setShowNewPassword] = useState({
    hide: true,
    info: 'Show',
  });

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };

  const isSelf = false;
  const isUserAdmin = true;

  const getUsers = useMemo(
    () =>
      _.isObject(rotaPadTeam)
        ? Object.keys(rotaPadTeam).map((key) => {
            return {
              name:
                rotaPadTeam[key].firstname + ' ' + rotaPadTeam[key].lastname,
              value: rotaPadTeam[key].uid,
            };
          })
        : [{}],
    [rotaPadTeam]
  );
  // console.log("rotaPadTeam AddAbsencesForm ==== ", rotaPadTeam);

  return (
    <div className='w-100 p-3'>
      <div className='row g-3'>
        <div className='col-12 mb-1'>
          <Field
            name='leavePolicy'
            render={({ input, meta }) => (
              <div className='field mb-1'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='role'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Request <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Dropdown
                      id='leavePolicy'
                      {...input}
                      options={REQUEST_TYPES}
                      optionLabel='name'
                      className={classNames({
                        'p-invalid': isFormFieldValid(meta),
                        customDropdown: true,
                      })}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='startEndDateTimeRange'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='startEndDateTimeRange'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Start and End Date <span className='text-danger'>*</span>
                  </StyledLabel>

                  <StyledInput>
                    <Calendar
                      panelClassName='requestLeaveCustomPicker'
                      id='startEndDateTimeRange'
                      {...input}
                      value={input.value ? input.value : null}
                      onChange={(v) => {
                        input.onChange(v.value ? v.value : null);
                      }}
                      className={classNames({
                        'p-invalid': isFormFieldValid(meta),
                      })}
                      selectionMode='range'
                      // readOnlyInput
                      minDate={new Date()}
                    />
                  </StyledInput>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>
        <div className='col-12 mt-1 mb-1'>
          <Field
            name='reason'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='reason'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Reason
                  </StyledLabel>

                  <StyledInputTextArea
                    id='reason'
                    {...input}
                    rows={5}
                    cols={30}
                    placeholder='Enter description'
                    className='w-100 p-inputtext-sm'
                    disabled={!isUserAdmin}
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        {/* <div className='col-12 mb-1'>
          <Field
            name='user'
            render={({ input, meta }) => (
              <div className='field mb-1'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='user'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Requested by <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Dropdown
                      id='user'
                      {...input}
                      options={getUsers}
                      optionLabel='name'
                      className={classNames({
                        'p-invalid': isFormFieldValid(meta),
                        customDropdown: true,
                      })}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div> */}

        {/* <div className='col-12 mb-1'>
          <Field
            name='approver'
            render={({ input, meta }) => (
              <div className='field mb-1'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='approver'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Approver <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Dropdown
                      id='approver'
                      {...input}
                      options={getUsers}
                      optionLabel='name'
                      className={classNames({
                        'p-invalid': isFormFieldValid(meta),
                        customDropdown: true,
                      })}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div> */}
      </div>
    </div>
  );
};

export default AddAbsencesForm;
