import styled from '@emotion/styled';
import { ButtonBase } from 'components/common';
import { useBranding } from 'components/common/hooks/useBranding';
import { customerColourStylesFx } from 'components/dashboard/AddShiftForm';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';

const StyledInputTextArea = styled.textarea`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  padding-top: 16px;
`;
const StyledDiv = styled.div`
  right: 12px !important;
  top: 40px !important;
  cursor: pointer;
  color: #6c757d;

  &:hover {
    color: #7f00ff;
  }

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;

const AddIntegrationsForm = ({
  handleSubmit,
  _id,
  pristine,
  submitting,
  hasValidationErrors,
  visibleLeft,
}) => {
  const [showNewPassword, setShowNewPassword] = useState({
    hide: true,
    info: 'Show',
  });
  const {
    colour: { primary },
  } = useBranding();

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };

  const isSelf = false;
  const isUserAdmin = true;

  return (
    <div className='w-100 p-3'>
      <div className='row g-3'>
        <div className='col-12 mb-1 text-muted'>
          Connect seamlessly with your WhatsApp, SMS numbers or email addresses
          by integrating its messages into your dashboard!
        </div>
        <div className='col-12 mt-2'>
          <Field
            name='type'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='type'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Type <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Select
                      id='type'
                      placeholder='Select type'
                      {...input}
                      styles={customerColourStylesFx(primary)}
                      value={{
                        label: input.value,
                        value: input.value,
                      }}
                      onChange={(x) => {
                        input.onChange(x && x.value ? x.value : undefined);
                      }}
                      options={[
                        {
                          label: 'WhatsApp',
                          value: 'WhatsApp',
                        },
                        { label: 'Email', value: 'Email' },
                        { label: 'SMS', value: 'SMS' },
                      ]}
                      isDisabled={!isUserAdmin}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mb-1 mt-1'>
          <Field
            name='name'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='name'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Name <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='name'
                    {...input}
                    className={classNames({
                      'p-invalid': isFormFieldValid(meta),
                    })}
                    disabled={!isUserAdmin}
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='description'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel htmlFor='description'>Description</StyledLabel>
                  <StyledInputTextArea
                    id='description'
                    placeholder='Enter description'
                    rows={5}
                    cols={30}
                    {...input}
                    className='w-100 p-inputtext-sm'
                    disabled={!isUserAdmin}
                  />
                </div>
              </div>
            )}
          />
        </div>

        <Field
          name='type'
          subscription={{ value: true }}
        >
          {({ input: { value: typeValue } }) => (
            <>
              {typeValue === 'Email' && (
                <div className='col-12 mt-1 mb-3 pb-4 border-bottom'>
                  <Field
                    name='email'
                    render={({ input, meta }) => (
                      <div className='field'>
                        <div className='block'>
                          <StyledLabel
                            htmlFor='email'
                            className={classNames({
                              'p-error': isFormFieldValid(meta),
                            })}
                          >
                            Email Address <span className='text-danger'>*</span>
                          </StyledLabel>
                          <StyledInput
                            id='email'
                            {...input}
                            className={classNames({
                              'p-invalid': isFormFieldValid(meta),
                            })}
                            disabled={!isUserAdmin}
                          />
                        </div>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}
                  />

                  <div className='d-flex flex-row mb-0 w-50 mt-4'>
                    <ButtonBase
                      type='submit'
                      variant='outline'
                      size='small'
                      disabled={true}
                    >
                      Buy custom email address
                    </ButtonBase>
                  </div>
                </div>
              )}

              {typeValue === 'SMS' && (
                <div className='col-12 mt-1 mb-3 pb-4 border-bottom'>
                  <Field
                    name='smsNumber'
                    render={({ input, meta }) => (
                      <div className='field'>
                        <div className='block'>
                          <StyledLabel
                            htmlFor='smsNumber'
                            className={classNames({
                              'p-error': isFormFieldValid(meta),
                            })}
                          >
                            SMS Number <span className='text-danger'>*</span>
                          </StyledLabel>
                          <StyledInput
                            id='smsNumber'
                            {...input}
                            className={classNames({
                              'p-invalid': isFormFieldValid(meta),
                            })}
                            disabled={!isUserAdmin}
                          />
                        </div>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}
                  />

                  <div
                    className='d-flex flex-row mb-0 mt-4'
                    style={{ width: '100px' }}
                  >
                    <ButtonBase
                      type='submit'
                      variant='outline'
                      size='small'
                      disabled={true}
                    >
                      Buy a number
                    </ButtonBase>
                  </div>
                </div>
              )}

              {typeValue === 'WhatsApp' && (
                <div className='col-12 mt-1 mb-3 pb-4 border-bottom'>
                  <Field
                    name='whatsAppNumber'
                    render={({ input, meta }) => (
                      <div className='field'>
                        <div className='block'>
                          <StyledLabel
                            htmlFor='whatsAppNumber'
                            className={classNames({
                              'p-error': isFormFieldValid(meta),
                            })}
                          >
                            WhatsApp Number{' '}
                            <span className='text-danger'>*</span>
                          </StyledLabel>
                          <StyledInput
                            id='whatsAppNumber'
                            {...input}
                            className={classNames({
                              'p-invalid': isFormFieldValid(meta),
                            })}
                            disabled={!isUserAdmin}
                          />
                        </div>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}
                  />

                  <div
                    className='d-flex flex-row mb-0 mt-4'
                    style={{ width: '100px' }}
                  >
                    <ButtonBase
                      type='submit'
                      variant='outline'
                      size='small'
                      disabled={true}
                    >
                      Buy a number
                    </ButtonBase>
                  </div>
                </div>
              )}
            </>
          )}
        </Field>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='active'
            type='checkbox'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <Checkbox
                    inputId='disabled'
                    {...input}
                    disabled={!isUserAdmin}
                  />
                  <StyledLabel
                    htmlFor='disabled'
                    className='ms-2'
                  >
                    Active
                  </StyledLabel>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AddIntegrationsForm;
