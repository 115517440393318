import styled from '@emotion/styled';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useCallback, useState } from 'react';
import { Field } from 'react-final-form';

import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment';
import { BsHouses, BsPeople } from 'react-icons/bs';
import { RiEditLine } from 'react-icons/ri';
import { AiOutlineDelete } from 'react-icons/ai';
import { generate as generateUUID } from 'short-uuid';
import _ from 'lodash';

const StyledInputTextArea = styled.textarea`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  padding-top: 16px;
`;
const StyledDiv = styled.div`
  right: 12px !important;
  top: 40px !important;
  cursor: pointer;
  color: #6c757d;

  &:hover {
    color: #7f00ff;
  }

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;
export const StyledInputPayRate = styled.div`
  input {
    padding: 8px 8px;
    font-size: 12px;
    width: 100% !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    color: #495057;
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    margin: 0;

    &:hover {
      border-color: #6366f1;
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #c7d2fe;
      border-color: #6366f1;
    }
    &:disabled {
      background-color: #e9ecef;
      opacity: 0.6;
      &:hover {
        border-color: #ced4da;
      }
    }
  }
`;
const StyledDivCustomer = styled.div`
  transition: background-color 0.2s ease 0s;
  background-color: #f4f5f7;
  border-radius: 6px;
  margin: 0px;

  &:hover {
    background-color: #f5ebff;
    cursor: inherit;
  }
`;

const StyledCustomerName = styled.div`
  font-size: 12px;
  font-weight: 500;
  width: 175px;
`;

const StyledCustomerID = styled.div`
  font-size: 10px;
  color: #797979;
  width: 175px;
`;
const CustomerStaff = ({
  email,
  id,
  uid,
  value,
  onChange,
  firstname,
  lastname,
  phone,
  setDisableSubmitBtn,
}) => {
  const [showCustomerStaffEditForm, setShowCustomerStaffEditForm] =
    useState(false);
  const [showNewIdError, setShowNewIdError] = useState(false);
  const [showNewIdDuplicateError, setShowNewIdDuplicateError] = useState(false);
  const [showNewFnameError, setShowFnameError] = useState(false);
  const [showNewLnameError, setShowLnameError] = useState(false);
  const [showNewEmailError, setShowEmailError] = useState(false);
  const [showNewNotEmailError, setShowNotEmailError] = useState(false);

  const handleUpdateUnitAddress = useCallback(
    (newAddress) => {
      // console.log('value ===== ', value);
      // console.log('newAddress ==== ', newAddress);
      const { customerStaff } = _.find(value, { id });

      if (!newAddress) {
        setShowEmailError(true);
        setDisableSubmitBtn(true);
        // return;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newAddress)
      ) {
        // errors.email = 'Invalid email address. E.g. example@email.com';
        setShowEmailError(false);
        setShowNotEmailError(true);
        setDisableSubmitBtn(true);
      } else if (
        customerStaff &&
        _.find(customerStaff, { email: newAddress })
      ) {
        setShowNewIdDuplicateError(true);
        setShowEmailError(false);
        setShowNotEmailError(false);
        setDisableSubmitBtn(true);
      } else {
        setShowEmailError(false);
        setShowNotEmailError(false);
        setShowNewIdDuplicateError(false);
        setDisableSubmitBtn(false);
      }

      const newValues = value.map((unit) => {
        if (unit.id === id) {
          unit.customerStaff = unit.customerStaff.map((cs) => {
            if (cs.uid === uid) {
              cs.email = newAddress;
            }
            return cs;
          });
        }

        return unit;
      });

      onChange(newValues);
    },
    [id, value, onChange, uid]
  );

  const handleUpdateFname = useCallback(
    (newAddress) => {
      if (!newAddress) {
        setShowFnameError(true);
        setDisableSubmitBtn(true);
        // return;
      } else {
        setShowFnameError(false);
        setDisableSubmitBtn(false);
      }
      // console.log('newAddress ==== ', newAddress);
      const newValues = value.map((unit) => {
        if (unit.id === id) {
          unit.customerStaff = unit.customerStaff.map((cs) => {
            if (cs.uid === uid) {
              cs.firstname = newAddress;
            }
            return cs;
          });
        }

        return unit;
      });
      onChange(newValues);
    },
    [id, value, onChange, uid]
  );

  const handleUpdateLname = useCallback(
    (newAddress) => {
      if (!newAddress) {
        setShowLnameError(true);
        setDisableSubmitBtn(true);
        // return;
      } else {
        setShowLnameError(false);
        setDisableSubmitBtn(false);
      }
      const newValues = value.map((unit) => {
        if (unit.id === id) {
          unit.customerStaff = unit.customerStaff.map((cs) => {
            if (cs.uid === uid) {
              cs.lastname = newAddress;
            }
            return cs;
          });
        }

        return unit;
      });
      onChange(newValues);
    },
    [id, value, onChange, uid]
  );

  // handleUpdateUnitPhone
  const handleUpdateUnitPhone = useCallback(
    (newAddress) => {
      const newValues = value.map((unit) => {
        if (unit.id === id) {
          unit.customerStaff = unit.customerStaff.map((cs) => {
            if (cs.uid === uid) {
              cs.phone = newAddress;
            }
            return cs;
          });
        }

        return unit;
      });
      onChange(newValues);
    },
    [id, value, onChange, uid]
  );
  const handleDeleteUnit = useCallback(() => {
    // console.log('value ===== ', value);
    // const newValues = value.filter((unit) => unit.id !== id);
    const newValues = value.map((unit) => {
      if (unit.id === id) {
        unit.customerStaff = unit.customerStaff.filter((cs) => cs.uid !== uid);
      }

      return unit;
    });
    onChange([...newValues]);
  }, [id, value, onChange, uid]);
  return (
    <div className='g-3 mb-1 mt-0 py-2 px-4 '>
      <div className='col-12 mt-0 mb-0 pb-2 border-bottom'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex flex-column'>
            <StyledCustomerName className='text-truncate'>
              {`${firstname} ${lastname}`}
            </StyledCustomerName>
            <StyledCustomerID className='text-truncate'>
              {email || uid}
            </StyledCustomerID>
          </div>
          <div>
            <button
              onClick={() =>
                setShowCustomerStaffEditForm(!showCustomerStaffEditForm)
              }
              type='button'
              className={`btn btn-outline-primary btnPrimaryChipFooter btn-sm me-1 `}
            >
              <RiEditLine
                style={{
                  fontSize: '12px',
                  marginTop: '-4px',
                }}
              />
            </button>

            <button
              onClick={handleDeleteUnit}
              type='button'
              className={`btn btn-outline-primary btnPrimaryChipFooter btn-sm me-0 `}
            >
              <AiOutlineDelete
                style={{
                  fontSize: '12px',
                  marginTop: '-4px',
                }}
              />
            </button>
          </div>
        </div>
      </div>
      {showCustomerStaffEditForm && (
        <div>
          <div className='col-12 mt-1 mb-0'>
            <div className='field'>
              <div className='block'>
                <StyledLabel
                  htmlFor='firstname'
                  className={classNames({
                    'p-error': showNewFnameError,
                  })}
                >
                  First name <span className='text-danger'>*</span>
                </StyledLabel>
                <StyledInput
                  id='firstname'
                  value={firstname}
                  onChange={(x) => handleUpdateFname(x.target.value)}
                  placeholder='First name'
                />
              </div>
              {showNewFnameError && (
                <small className='p-error'>First name is required</small>
              )}
            </div>
          </div>
          <div className='col-12 mt-1 mb-0'>
            <div className='field'>
              <div className='block'>
                <StyledLabel
                  htmlFor='lastname'
                  className={classNames({
                    'p-error': showNewLnameError,
                  })}
                >
                  Last name <span className='text-danger'>*</span>
                </StyledLabel>
                <StyledInput
                  id='lastname'
                  value={lastname}
                  onChange={(x) => handleUpdateLname(x.target.value)}
                  placeholder='Last name'
                />
              </div>
              {showNewLnameError && (
                <small className='p-error'>Last name is required</small>
              )}
            </div>
          </div>
          <div className='col-12 mt-1 mb-0'>
            <div className='field'>
              <div className='block'>
                <StyledLabel
                  htmlFor='email'
                  className={classNames({
                    'p-error':
                      showNewEmailError ||
                      showNewNotEmailError ||
                      showNewIdDuplicateError,
                  })}
                >
                  Email <span className='text-danger'>*</span>
                </StyledLabel>
                <StyledInput
                  id='email'
                  value={email}
                  onChange={(x) => handleUpdateUnitAddress(x.target.value)}
                  placeholder='Email'
                />
              </div>
              {showNewEmailError && (
                <small className='p-error'>Email address is required</small>
              )}
              {showNewNotEmailError && (
                <small className='p-error'>Email is not valid</small>
              )}
              {showNewIdDuplicateError && (
                <small className='p-error'>Email already exist</small>
              )}
            </div>
          </div>

          <div className='col-12 mt-1 mb-0'>
            <div className='field'>
              <div className='block'>
                <StyledLabel
                  htmlFor='phone'
                  // className={classNames({
                  //   'p-error':
                  //     showNewEmailError ||
                  //     showNewNotEmailError ||
                  //     showNewIdDuplicateError,
                  // })}
                >
                  Phone
                </StyledLabel>
                <StyledInput
                  id='phone'
                  value={phone}
                  onChange={(x) => handleUpdateUnitPhone(x.target.value)}
                  placeholder='Enter phone number'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const UnitComponent = ({
  id,
  unitId,
  name,
  meta,
  isFormFieldValid,
  isUserAdmin,
  getFormErrorMessage,
  value,
  address,
  onChange,
  customerStaff,
  handleAddCustomerStaff,
  setDisableSubmitBtn,
}) => {
  const [showUnitEditForm, setShowUnitEditForm] = useState(false);
  const [showNewIdError, setShowNewIdError] = useState(false);
  const [showNewIdDuplicateError, setShowNewIdDuplicateError] = useState(false);
  const [showNewNameError, setShowNewNameError] = useState(false);

  const handleDeleteUnit = useCallback(() => {
    // console.log('value ===== ', value);
    const newValues = value.filter((unit) => unit.id !== id);
    onChange([...newValues]);
  }, [id, value, onChange]);

  const handleUpdateUnitId = useCallback(
    (newId) => {
      // console.log('handleUpdateUnitId value ===== ', value);
      if (!newId) {
        setShowNewIdError(true);
        setDisableSubmitBtn(true);
        setShowNewIdDuplicateError(false);
      } else {
        setShowNewIdError(false);
        if (_.find(value, { unitId: newId })) {
          setShowNewIdDuplicateError(true);
          setDisableSubmitBtn(true);
        } else {
          setShowNewIdDuplicateError(false);
          setDisableSubmitBtn(false);
        }
      }

      const newValues = value.map((unit) => {
        if (unit.id === id) {
          unit.unitId = newId;
        }

        return unit;
      });
      onChange([...newValues]);
    },
    [id, value, onChange]
  );

  const handleUpdateUnitName = useCallback(
    (newName) => {
      // console.log('value ===== ', value);
      const newValues = value.map((unit) => {
        if (unit.id === id) {
          unit.name = newName;
        }

        return unit;
      });
      onChange([...newValues]);

      if (!newName) {
        setShowNewNameError(true);
        setDisableSubmitBtn(true);
      } else {
        setShowNewNameError(false);
        setDisableSubmitBtn(false);
      }
    },
    [id, value, onChange]
  );

  const handleUpdateUnitAddress = useCallback(
    (newAddress) => {
      // console.log('value ===== ', value);
      const newValues = value.map((unit) => {
        if (unit.id === id) {
          unit.address = newAddress;
        }

        return unit;
      });
      onChange([...newValues]);
    },
    [id, value, onChange]
  );

  const handleUpdateUnitCustomerStaff = useCallback(
    (newAddress) => {
      // console.log('value ===== ', value);
      // const newValues = value.map((unit) => {
      //   if (unit.id === id) {
      //     unit.email = newAddress;
      //   }

      //   return unit;
      // });
      onChange(newAddress);
    },
    [id, value, onChange]
  );
  return (
    <div className='mt-0'>
      <StyledDivCustomer
        className='row g-3 mb-1 py-2'
        // key={id}
      >
        <div className='col-12 mt-0 mb-0'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex flex-column'>
              <StyledCustomerName className='text-truncate'>
                {name}
              </StyledCustomerName>
              <StyledCustomerID className='text-truncate'>
                {unitId}
              </StyledCustomerID>
            </div>
            {isUserAdmin && (
              <div>
                <button
                  onClick={() => setShowUnitEditForm(!showUnitEditForm)}
                  type='button'
                  className={`btn btn-outline-primary btnPrimaryChipFooter btn-sm me-1 `}
                >
                  <RiEditLine
                    style={{
                      fontSize: '12px',
                      marginTop: '-4px',
                    }}
                  />
                </button>

                <button
                  onClick={handleDeleteUnit}
                  type='button'
                  className={`btn btn-outline-primary btnPrimaryChipFooter btn-sm me-0 `}
                >
                  <AiOutlineDelete
                    style={{
                      fontSize: '12px',
                      marginTop: '-4px',
                    }}
                  />
                </button>
              </div>
            )}
          </div>
        </div>

        {showUnitEditForm && (
          <>
            <div className='col-12 mt-1 mb-0'>
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='name'
                    className={classNames({
                      'p-error': showNewNameError,
                    })}
                  >
                    Name <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='unitId'
                    value={name}
                    onChange={(x) => handleUpdateUnitName(x.target.value)}
                    placeholder='Unit name'
                  />
                </div>
                {showNewNameError && (
                  <small className='p-error'>Unit name is required</small>
                )}
              </div>
            </div>

            <div className='col-12 mt-2 mb-0'>
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='unitId'
                    className={classNames({
                      'p-error': showNewIdError || showNewIdDuplicateError,
                    })}
                  >
                    Unit ID <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='unitId'
                    value={unitId}
                    placeholder='Unit ID'
                    onChange={(x) => handleUpdateUnitId(x.target.value)}
                  />
                </div>
                {showNewIdError && (
                  <small className='p-error'>Unit ID is required</small>
                )}
                {showNewIdDuplicateError && (
                  <small className='p-error'>Duplicate unit ID</small>
                )}
              </div>
            </div>

            <div className='col-12 mt-1 mb-0'>
              <div className='field'>
                <div className='block'>
                  <StyledLabel htmlFor='description'>Address</StyledLabel>
                  <StyledInputTextArea
                    id='description'
                    placeholder='Enter description'
                    rows={5}
                    cols={30}
                    value={address}
                    onChange={(x) => handleUpdateUnitAddress(x.target.value)}
                    className='w-100 p-inputtext-sm'
                  />
                </div>
              </div>
            </div>

            <StyledH5 className='mt-3 mb-2 ps-4 border-top'>
              Customer Staff{' '}
              {isUserAdmin && (
                <button
                  onClick={() => handleAddCustomerStaff(id)}
                  type='button'
                  className='btn btn-sm btn-outline-primary btnPrimaryComments me-2'
                >
                  Add
                </button>
              )}
            </StyledH5>
            <div className='col-12 mb-1 mt-0 ps-4'>
              <div className='field'>
                <div className='block'>
                  <BsPeople
                    style={{
                      // marginRight: '8px',
                      marginTop: '-4px',
                      fontSize: '1.0rem',
                      color: '#ff6200d9',
                    }}
                  />
                  <StyledLabel
                    htmlFor='zero'
                    className='ms-2'
                    style={{
                      color: '#ff6200d9',
                    }}
                  >
                    {customerStaff ? (
                      <>
                        {`${customerStaff && customerStaff?.length}`} customer
                        staff member
                        {`${
                          (customerStaff && customerStaff?.length > 1) ||
                          (customerStaff && customerStaff?.length === 0)
                            ? 's '
                            : ' '
                        }`}
                      </>
                    ) : (
                      <>0 customer staff members</>
                    )}
                  </StyledLabel>
                </div>
              </div>
            </div>
            {customerStaff &&
              customerStaff.length > 0 &&
              customerStaff.map(
                ({ uid, email, firstname, lastname, phone }) => {
                  return (
                    <CustomerStaff
                      key={uid}
                      id={id}
                      uid={uid}
                      value={value}
                      email={email}
                      firstname={firstname}
                      lastname={lastname}
                      phone={phone}
                      onChange={handleUpdateUnitCustomerStaff}
                      setDisableSubmitBtn={setDisableSubmitBtn}
                    ></CustomerStaff>
                  );
                }
              )}

            {/* <span onClick={() => handleAddCustomerStaff(id)}>
              Add customer staff
            </span> */}

            <div className='col-12 mt-2 pt-3 mb-2 border-top'>
              <button
                onClick={() => setShowUnitEditForm(false)}
                type='button'
                className='btn btn-sm btn-outline-primary btnPrimaryComments me-1 float-end'
              >
                Ok
              </button>
              {/* <button
              type='button'
              className='btn btn-sm btn-outline-primary btnPrimaryComments me-2 float-end'
            >
              Cancel
            </button> */}
            </div>
          </>
        )}
      </StyledDivCustomer>
    </div>
  );
};

const UnitsFieldAdapter = ({
  input: { onChange, value },
  isUserAdmin,
  setDisableSubmitBtn,
  ...rest
}) => {
  // console.log('input ===== ', value);

  const handleAddUnit = useCallback(() => {
    // console.log('value ==== ', value);
    onChange([
      ...value,
      {
        id: generateUUID(),
        unitId: generateUUID(),
        name: 'Unit name',
        address: '',
        createdAt: moment().format(),
        customerStaff: [
          // {
          //   // uid: 'fsd',
          //   uid: generateUUID(),
          //   email: '',
          //   firstname: '',
          //   lastname: '',
          //   createdAt: moment().format(),
          // },
        ],
        // {customerStaff:[]}
      },
    ]);
  }, [onChange, value]);

  const handleAddCustomerStaff = useCallback(
    (id) => {
      // console.log('value ==== ', value);
      onChange(
        value.map((val) => {
          if (val.id === id) {
            val.customerStaff = val.customerStaff
              ? [
                  ...val.customerStaff,
                  {
                    // uid: 'fsd',
                    uid: generateUUID(),
                    email: 'sample@abc.com',
                    firstname: 'FirstName',
                    lastname: 'LastName',
                    phone: '',
                    createdAt: moment().format(),
                  },
                ]
              : [
                  // ...(val.customerStaff && val.customerStaff),
                  {
                    // uid: 'fsd',
                    uid: generateUUID(),
                    email: 'sample@abc.com',
                    firstname: 'FirstName',
                    lastname: 'LastName',
                    phone: '',
                    createdAt: moment().format(),
                  },
                ];
          }
          return val;
        })
      );
    },
    [onChange, value]
  );
  return (
    <>
      <StyledH5 className='mt-3 mb-2'>
        Units{' '}
        {isUserAdmin && (
          <button
            onClick={handleAddUnit}
            type='button'
            className='btn btn-sm btn-outline-primary btnPrimaryComments me-2'
          >
            Add
          </button>
        )}
      </StyledH5>
      <div className='col-12 mb-1'>
        <div className='field'>
          <div className='block'>
            <BsHouses
              style={{
                // marginRight: '8px',
                marginTop: '-4px',
                fontSize: '1.0rem',
                color: '#ff6200d9',
              }}
            />
            <StyledLabel
              htmlFor='zero'
              className='ms-2'
              style={{
                color: '#ff6200d9',
              }}
            >
              {`${value?.length}`} home
              {`${value?.length > 1 || value?.length === 0 ? 's ' : ' '}`}
              assigned
            </StyledLabel>
          </div>
        </div>
      </div>

      {value &&
        value.length > 0 &&
        value.map(({ id, unitId, name, address, customerStaff }) => {
          return (
            <UnitComponent
              {...rest}
              key={id}
              id={id}
              unitId={unitId}
              name={name}
              address={address}
              value={value}
              onChange={onChange}
              isUserAdmin={isUserAdmin}
              customerStaff={customerStaff}
              handleAddCustomerStaff={handleAddCustomerStaff}
              setDisableSubmitBtn={setDisableSubmitBtn}
            />
          );
        })}

      {/* {customerStaff:[]} */}
    </>
  );
};

const AddCustomerForm = ({
  handleSubmit,
  _id,
  pristine,
  submitting,
  hasValidationErrors,
  visibleLeft,
  setDisableSubmitBtn,
}) => {
  const [showNewPassword, setShowNewPassword] = useState({
    hide: true,
    info: 'Show',
  });

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };

  const isSelf = false;
  const isUserAdmin = true;

  return (
    <div className='w-100 p-3'>
      <div className='row g-3'>
        {/* <div className='col-12 mb-0 text-muted'>
          Adjust workspace-wide settings and preferences. You can also manage
          members and roles.
        </div> */}
        <div className='col-12 mb-0'>
          <Field
            name='name'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='name'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Name <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='name'
                    {...input}
                    className={classNames({
                      'p-invalid': isFormFieldValid(meta),
                    })}
                    disabled={!isUserAdmin}
                    placeholder='Enter customer name'
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>
        <div className='col-12 mb-1'>
          <Field
            name='customerId'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='customerId'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Customer ID <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='customerId'
                    {...input}
                    className={classNames({
                      'p-invalid': isFormFieldValid(meta),
                    })}
                    disabled={!isUserAdmin}
                    placeholder='Enter customer ID'
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='address'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel htmlFor='address'>
                    Address <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInputTextArea
                    id='address'
                    placeholder='Enter address'
                    rows={5}
                    cols={30}
                    {...input}
                    className='w-100 p-inputtext-sm'
                    disabled={!isUserAdmin}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <div className='col-12 mt-1 mb-1'>
          <Field
            name='payRate'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel htmlFor='phone'>Pay rate</StyledLabel>
                  <StyledInputPayRate>
                    {' '}
                    <InputNumber
                      inputId='stacked'
                      // {...input}
                      value={input.value || 0}
                      onValueChange={(e) => {
                        input.onChange(e.value);
                      }}
                      showButtons
                      mode='currency'
                      currency='NGN'
                      className='w-100'
                      disabled={!isUserAdmin}
                    />
                  </StyledInputPayRate>
                </div>
              </div>
            )}
          />
        </div>
        <div className='col-12 mt-1 mb-1'>
          <Field
            name='sleepRate'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel htmlFor='phone'>Sleep rate</StyledLabel>
                  <StyledInputPayRate>
                    {' '}
                    <InputNumber
                      inputId='stacked'
                      // {...input}
                      value={input.value || 0}
                      onValueChange={(e) => {
                        input.onChange(e.value);
                      }}
                      showButtons
                      mode='currency'
                      currency='NGN'
                      className='w-100'
                      disabled={!isUserAdmin}
                    />
                  </StyledInputPayRate>
                </div>
              </div>
            )}
          />
        </div>
        <div className='col-12 mt-1 mb-4 pb-2'>
          <Field
            name='disabled'
            type='checkbox'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <Checkbox
                    inputId='disabled'
                    {...input}
                    disabled={!isUserAdmin}
                  />
                  <StyledLabel
                    htmlFor='disabled'
                    className='ms-2'
                  >
                    Account Disabled
                  </StyledLabel>
                </div>
              </div>
            )}
          />
        </div>
        <Field
          name='units'
          component={UnitsFieldAdapter}
          isFormFieldValid={isFormFieldValid}
          isUserAdmin={isUserAdmin}
          getFormErrorMessage={getFormErrorMessage}
          setDisableSubmitBtn={setDisableSubmitBtn}
        />
      </div>
    </div>
  );
};

export default AddCustomerForm;
