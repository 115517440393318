// Login component

import styled from '@emotion/styled';
import {
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from 'firebase/auth';
import { ref, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonBase, ToolTip } from '../common';
import { TOAST_TYPE } from '../common/constants';
import { useBranding } from '../common/hooks/useBranding';
import { useToast } from '../common/hooks/useToast';
import Logo from '../common/img/logo.png';
import './index.scss';
import { Helmet } from 'react-helmet';

const StyledDiv = styled.div`
  cursor: pointer;
  z-index: 200;
  position: absolute;
  right: 15px;
  font-size: large;
  color: #6c757d;
  top: 16px;

  &:hover {
    color: #7f00ff;
  }
`;

const StyledInput = styled.input`
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

function writeUserData(db, userId, name, email, imageUrl) {
  // const db = getDatabase();
  // set(ref(db, "users/" + userId), {
  // 	username: name,
  // 	email: email,
  // 	profile_picture: imageUrl,
  // });

  set(ref(db, 'rotas/2023/workorders/555'), {
    title: '555',
  });
}
const Login = ({ app, analytics, auth, database }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    hide: true,
    info: 'Show password',
  });

  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { triggerToast } = useToast();
  const { logo, loginParagraph } = useBranding();

  // Remove trailing route string and redirect to '/'
  useEffect(() => {
    if (navigate || pathname) {
      navigate('/');
    }
  }, [navigate, pathname]);

  const handleLogin = async () => {
    setIsLoginLoading(true);
    try {
      const persistSession = await setPersistence(
        auth,
        browserSessionPersistence
      );
      const getuser = await signInWithEmailAndPassword(auth, email, password);
      // console.log("User logged in successfully! ===== ", getuser.user);

      setIsLoginLoading(false);
      navigate('/dashboard');
      //
    } catch (error) {
      console.log(error.message);
      setIsLoginLoading(false);
      triggerToast({
        message: error.message.replace('Firebase: ', ''),
        type: TOAST_TYPE.warning,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Sign in | RotaPad`}</title>
      </Helmet>
      <div className={`formBody text-center `}>
        <div className='form-signin w-100 m-auto'>
          <form noValidate>
            {logo?.fullLogo ? (
              <>
                <img
                  src={logo?.fullLogo}
                  alt='Logo'
                  className='mb-4'
                  style={{ width: '63%' }}
                />
                <br />
                <img
                  src={Logo}
                  width='100'
                  height='21'
                  className='mb-4'
                  alt='ROTA PAD logo'
                />
              </>
            ) : (
              <img
                src={Logo}
                width='140'
                height='32'
                className='mb-4'
                alt='ROTA PAD logo'
              />
            )}

            <h1 className='h3 mb-3 fw-normal'>Sign in</h1>

            <div className='form-floating'>
              <input
                type='email'
                className='form-control'
                placeholder='name@example.com'
                name='email'
                id='email'
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete='username'
              />
              <label>Email address</label>
            </div>
            <div className='form-floating'>
              <StyledDiv
                onClick={() => {
                  setShowPassword({
                    hide: !showPassword.hide,
                    info: showPassword.hide ? 'Hide password' : 'Show password',
                  });
                }}
              >
                <ToolTip
                  placement='top'
                  overlay={
                    <span style={{ fontSize: 10 }}>{showPassword.info}</span>
                  }
                >
                  {showPassword.hide ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </ToolTip>
              </StyledDiv>
              <StyledInput
                type={showPassword.hide ? 'password' : 'text'}
                className='form-control'
                placeholder='Password'
                name='password'
                id='password'
                value={password || ''}
                autoComplete='current-password'
                onChange={(e) => setPassword(e.target.value)}
              />
              <label>Password</label>
            </div>

            <div className='checkbox mb-3'>
              <label>
                <input
                  type='checkbox'
                  value='remember-me'
                />{' '}
                Remember me
              </label>
            </div>
            <ButtonBase
              variant='primary'
              size='large'
              onClick={(event) => {
                event.preventDefault();
                handleLogin();
              }}
              disabled={isLoginLoading || !email || !password}
            >
              Sign in{' '}
              {isLoginLoading && (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              )}
            </ButtonBase>

            <p className='mt-5 mb-1 text-muted'>&copy;2023 Rota Pad v3.0</p>
            {/* <p className='text-muted p-0'>{loginParagraph || ''}</p> */}
          </form>
        </div>
      </div>
    </>
  );
};

Login.defaultProps = {
  theme: { primary: 'red' },
};
export default Login;
