// Login component

import styled from '@emotion/styled';
import {
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
// import { ref, set } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonBase, ToolTip } from '../common';
import { TOAST_TYPE } from '../common/constants';
import { useBranding } from '../common/hooks/useBranding';
import { useToast } from '../common/hooks/useToast';
import Logo from '../common/img/logo.png';
import { FiInfo } from 'react-icons/fi';
import { MdOutlineRememberMe } from 'react-icons/md';
import { Field, Form } from 'react-final-form';
import { classNames } from 'primereact/utils';
import {
  useCreateUserWithEmailAndPassword,
  useUpdatePassword,
} from 'react-firebase-hooks/auth';
import zxcvbn from 'zxcvbn';
import { child, get, ref, remove, set, onValue } from 'firebase/database';
import _ from 'lodash';
import { convertObjectToArray } from '../common/utils';
import moment from 'moment';

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  margin-top: 8px;
`;

const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;
// import './index.scss';
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
`;
const StyledDiv = styled.div`
  cursor: pointer;
  z-index: 200;
  position: absolute;
  right: 10px !important;
  font-size: large;
  color: #6c757d;
  top: 44px !important;

  &:hover {
    color: #7f00ff;
  }
`;

const ErrorStyledDiv = styled.div`
  background-color: #fff;
  padding: 24px;
`;

// const StyledInput = styled.input`
//   margin-bottom: 10px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// `;

function writeUserData(db, userId, name, email, imageUrl) {
  // const db = getDatabase();
  // set(ref(db, "users/" + userId), {
  // 	username: name,
  // 	email: email,
  // 	profile_picture: imageUrl,
  // });

  set(ref(db, 'rotas/2023/workorders/555'), {
    title: '555',
  });
}
const RegisterPage = ({ app, analytics, auth, database }) => {
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    hide: true,
    info: 'Show password',
  });

  const [updatePassword, updating, error] = useUpdatePassword(auth);

  const [showNewPassword, setShowNewPassword] = useState({
    hide: true,
    info: 'Show password',
  });

  const [showConfirmPassword, setShowConfirmPassword] = useState({
    hide: true,
    info: 'Show password',
  });
  const [searchParams] = useSearchParams();

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };

  const validatePasswords = (data) => {
    let errors = {};

    if (!data.firstname) {
      errors.firstname = 'First Name is required.';
    }

    if (!data.lastname) {
      errors.lastname = 'Last Name is required.';
    }

    if (!data.email) {
      errors.email = 'Email is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = 'Invalid email address. E.g. example@email.com';
    }

    if (!data.newPassword) {
      errors.newPassword = 'New password is required.';
    }

    if (!data.confirmPassword) {
      errors.confirmPassword = 'This field is required.';
    }

    if (data.newPassword && data.newPassword.length < 12) {
      errors.newPassword = 'New password should be 12 or more characters long.';
    }

    const {
      score = 0,
      feedback: { suggestions, warning },
    } = zxcvbn(data.newPassword || '');

    if (data.newPassword && data.newPassword.length >= 12 && score < 4) {
      errors.newPassword = warning || suggestions[0];
    }

    if (data.confirmPassword && data.confirmPassword.length < 12) {
      errors.confirmPassword =
        'This password should be 12 or more characters long.';
    }

    if (
      data.confirmPassword &&
      data.confirmPassword.length >= 12 &&
      data.newPassword &&
      data.newPassword.length >= 12 &&
      data.newPassword !== data.confirmPassword
    ) {
      // errors.newPassword = "Password should match";
      errors.confirmPassword = 'Password should match';
    }

    return errors;
  };

  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { triggerToast } = useToast();
  const { logo, loginParagraph } = useBranding();

  // Remove trailing route string and redirect to '/'
  // useEffect(() => {
  //   if (navigate || pathname) {
  //     navigate('/');
  //   }
  // }, [navigate, pathname]);
  useEffect(() => {
    if (
      searchParams &&
      searchParams.get('_atemail') &&
      searchParams.get('_fname') &&
      searchParams.get('_lname') &&
      searchParams.get('_uuid')
    ) {
      // console.log('_fname ==== ', searchParams.get('_fname'));
      // console.log(
      //   '_atemail ==== ',
      //   searchParams.get('_atemail').split(' ').join('+')
      // );
      // console.log('_lname ==== ', searchParams.get('_lname'));
      // console.log('_uuid ==== ', searchParams.get('_uuid'));

      setEmail(searchParams.get('_atemail').split(' ').join('+'));
      setFname(searchParams.get('_fname'));
      setLname(searchParams.get('_lname'));
    }
  }, [searchParams, auth]);
  const handleSubmitUserDetails = useCallback(
    async (data, form) => {
      const { email, newPassword } = data;

      // const registerNewUser = await createUserWithEmailAndPassword(
      //   auth,
      //   email,
      //   password
      // );
      // console.log('email ===== ', email);
      // console.log('password ===== ', data);
      const persistSession = await setPersistence(
        auth,
        browserSessionPersistence
      );
      const newUser = await createUserWithEmailAndPassword(
        auth,
        email,
        newPassword
      );
      // await sendEmailVerification(newUser.user);

      // console.log('newUser ==== ', newUser);
      const dbRef = ref(database);
      const getInvites = await get(child(dbRef, `invites`));

      if (getInvites.exists()) {
        const invites = getInvites.val();
        // console.log('invites ===== ', invites);

        const getInvite = _.find(convertObjectToArray({ item: invites }), {
          email,
        });
        if (getInvite) {
          await set(ref(database, `users/${newUser?.user?.uid}`), {
            ...getInvite,
            uid: newUser?.user?.uid,
          });

          await remove(child(dbRef, `invites/${getInvite.uid}`));

          navigate('/');
        }
      } else {
        console.log('No shifts data available');
      }
      // .then((res) => {
      //     console.log(res.user);
      //     sendEmailVerification(res.user);
      //   })
      //   .catch((err) => {
      //     console.log('err.message ==== ', err.message);
      //   });
      // await sendEmailVerification(registerNewUser.user);
    },
    [auth]
  );

  return (
    <>
      <div className={`formBody `}>
        <ErrorStyledDiv className='shadow-sm form-signin w-100 m-auto'>
          {/* <form noValidate> */}
          {logo?.fullLogo ? (
            <div className='text-center '>
              <img
                src={logo?.fullLogo}
                alt='Logo'
                className='mb-4'
                style={{ width: '63%' }}
              />
              <br />
              <img
                src={Logo}
                width='100'
                height='21'
                className='mb-4'
                alt='ROTA PAD logo'
              />
            </div>
          ) : (
            <img
              src={Logo}
              width='140'
              height='32'
              className='mb-4'
              alt='ROTA PAD logo'
            />
          )}
          <div className='border-top border-bottom py-4 mb-4'>
            <div className='form-floating text-center '>
              <MdOutlineRememberMe
                style={{ fontSize: '36px', color: 'orange' }}
              />
            </div>
            <h1 className='h6 mt-2 mb-1 fw-normal text-center '>
              Create new password
            </h1>
            <p
              style={{ fontSize: '12px' }}
              className='text-center '
            >
              Fill out your details below to begin your RotaPad workspace
              experience. For assistance, please call <br />
              <span>+1 587 404 2004</span> or send an email to
              support@rotapad.com
            </p>

            <div>
              <Form
                onSubmit={handleSubmitUserDetails}
                initialValues={{
                  email: email,
                  firstname: fname,
                  lastname: lname,
                  newPassword: '',
                  confirmPassword: '',
                }}
                validate={validatePasswords}
                render={({
                  handleSubmit,
                  pristine,
                  submitting,
                  hasValidationErrors,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className='p-fluid'
                  >
                    <div className='row g-3 text-left'>
                      <div className='col-xs-12 col-md-12 mb-2'>
                        <Field
                          name='firstname'
                          render={({ input, meta }) => (
                            <div className='field'>
                              <div className='block'>
                                <StyledLabel
                                  htmlFor='firstname'
                                  className={classNames({
                                    'p-error': isFormFieldValid(meta),
                                  })}
                                >
                                  First Name{' '}
                                  <span className='text-danger'>*</span>
                                </StyledLabel>
                                <StyledInput
                                  id='firstname'
                                  {...input}
                                  className={classNames({
                                    'p-invalid': isFormFieldValid(meta),
                                  })}
                                  disabled
                                />
                              </div>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}
                        />
                        <Field
                          name='lastname'
                          render={({ input, meta }) => (
                            <div className='field'>
                              <div className='block'>
                                <StyledLabel
                                  htmlFor='lastname'
                                  className={classNames({
                                    'p-error': isFormFieldValid(meta),
                                  })}
                                >
                                  Last Name{' '}
                                  <span className='text-danger'>*</span>
                                </StyledLabel>
                                <StyledInput
                                  id='lastname'
                                  {...input}
                                  className={classNames({
                                    'p-invalid': isFormFieldValid(meta),
                                  })}
                                  disabled
                                />
                              </div>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}
                        />
                        <Field
                          name='email'
                          render={({ input, meta }) => (
                            <div className='field'>
                              <div className='block'>
                                <StyledLabel
                                  htmlFor='email'
                                  className={classNames({
                                    'p-error': isFormFieldValid(meta),
                                  })}
                                >
                                  Email <span className='text-danger'>*</span>
                                </StyledLabel>

                                <StyledInput
                                  id='email'
                                  {...input}
                                  className={classNames({
                                    'p-invalid': isFormFieldValid(meta),
                                  })}
                                  disabled
                                />
                              </div>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}
                        />
                        <Field
                          name='newPassword'
                          render={({ input, meta }) => (
                            <div className='field'>
                              <div className='block position-relative'>
                                <StyledLabel
                                  htmlFor='firstname'
                                  className={classNames({
                                    'p-error': isFormFieldValid(meta),
                                  })}
                                >
                                  New password{' '}
                                  <span className='text-danger'>*</span>
                                </StyledLabel>
                                <StyledInput
                                  id='newPassword'
                                  autoComplete='new-password'
                                  placeholder='Enter new password'
                                  type={
                                    showNewPassword.hide ? 'password' : 'text'
                                  }
                                  {...input}
                                  className={classNames({
                                    'p-invalid': isFormFieldValid(meta),
                                  })}
                                />
                                <ToolTip
                                  placement='top'
                                  overlay={
                                    <span
                                      style={{
                                        fontSize: 10,
                                      }}
                                    >
                                      {showNewPassword.info}
                                    </span>
                                  }
                                >
                                  <StyledDiv
                                    className='position-absolute top-50 end-0 translate-middle-y'
                                    onClick={() => {
                                      setShowNewPassword({
                                        hide: !showNewPassword.hide,
                                        info: showNewPassword.hide
                                          ? 'Hide password'
                                          : 'Show password',
                                      });
                                    }}
                                  >
                                    {showNewPassword.hide ? (
                                      <AiOutlineEyeInvisible />
                                    ) : (
                                      <AiOutlineEye />
                                    )}
                                  </StyledDiv>
                                </ToolTip>
                              </div>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}
                        />

                        <Field
                          name='confirmPassword'
                          render={({ input, meta }) => (
                            <div className='field'>
                              <div className='block position-relative'>
                                <StyledLabel
                                  htmlFor='confirmPassword'
                                  className={classNames({
                                    'p-error': isFormFieldValid(meta),
                                  })}
                                >
                                  Confirm new password{' '}
                                  <span className='text-danger'>*</span>
                                </StyledLabel>
                                <StyledInput
                                  id='confirmPassword'
                                  autoComplete='confirm-password'
                                  type={
                                    showConfirmPassword.hide
                                      ? 'password'
                                      : 'text'
                                  }
                                  {...input}
                                  className={classNames({
                                    'p-invalid': isFormFieldValid(meta),
                                  })}
                                  placeholder='Confirm new password'
                                />
                                <StyledDiv
                                  className='position-absolute top-50 end-0 translate-middle-y'
                                  onClick={() => {
                                    setShowConfirmPassword({
                                      hide: !showConfirmPassword.hide,
                                      info: showConfirmPassword.hide
                                        ? 'Hide password'
                                        : 'Show password',
                                    });
                                  }}
                                >
                                  {setShowConfirmPassword.hide ? (
                                    <AiOutlineEyeInvisible />
                                  ) : (
                                    <AiOutlineEye />
                                  )}
                                </StyledDiv>
                              </div>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}
                        />
                      </div>
                      <ButtonBase
                        variant='primary'
                        size='large'
                        type='submit'
                        // onClick={(event) => {
                        //   event.preventDefault();
                        //   navigate('/dashboard');
                        // }}
                        disabled={pristine || submitting || hasValidationErrors}
                        // disabled={isLoginLoading || !email || !password}
                      >
                        Join WestBrand Workspace
                        {isLoginLoading && (
                          <span
                            className='spinner-border spinner-border-sm'
                            role='status'
                            aria-hidden='true'
                          ></span>
                        )}
                      </ButtonBase>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>

          <p className='mt-5 mb-1 text-muted text-center'>
            &copy;2023 Rota Pad v3.0
          </p>
          {/* <p className='text-muted p-0'>{loginParagraph || ''}</p> */}
          {/* </form> */}
        </ErrorStyledDiv>
      </div>
    </>
  );
};

// RegisterPage.defaultProps = {
//   theme: { primary: 'red' },
// };
export default RegisterPage;
